import {
    Badge,
    Box,
    Button,    
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    RadioGroup,
    Radio,
    TextField,
    Typography,
    Tooltip
  } from '@mui/material';
  import React, { useEffect, useState, useRef } from 'react';
  import { Form, Formik, Field, FieldArray, ErrorMessage } from 'formik';
  import { DropzoneArea } from 'material-ui-dropzone';
  import { LoadingButton } from '@mui/lab';
  import { makeStyles } from '@mui/styles';
  import ClearIcon from '@mui/icons-material/Clear';
  import CloseIcon from '@mui/icons-material/Close';
  import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
  import * as Yup from 'yup';
  import ReactQuill, { Quill } from 'react-quill';
  import 'react-quill/dist/quill.snow.css'; 
  import Modal from '../../../components/modal/Index';

  // Define your modules and formats for ReactQuill
const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']                                        
    ],
  };
  
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];

const VideoScreen = ({
    loading,
    typeOfScreen,
    modalOpen,
    formType,
    buttonText,
    handleModalClose,
    handleSubmitForm,
    handleVideoDelete,
    data
}) => {
    const classes = useStyles();
    const [isMCQDisabled, setIsMCQDisabled] = useState(true);
    const [selectedValue, setSelectedValue] = useState('No'); // Initially set to "No"
    const [isContainVideo, setIsContainVideo] = useState(false);
    const [isloading, setisloading] = useState(false);

    const [initialValues, setInitialValues] = React.useState({
              isCourseAssessment: false,
              isSubModuleAssessment: false,              
              isMutipleChoice: false,
              mcqQuestion: '',
              mcqOptions: [{ option: '' }],
              correctAnswer: '',
              videoPreview: null,
              screenType: typeOfScreen,
              isCaseStudiesAssessment: false,
              caseStudiesQuestion: ''
    })
  const handleradioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    
    if(formType === 'EDIT' && data!==null){
        setisloading(true)
        
        setInitialValues({
            isCourseAssessment: data.isCourseAssessment,
            isSubModuleAssessment: data.isSubModuleAssessment,
            isMutipleChoice: data.isMutipleChoice,
            mcqOptions: data.mcqOptions,
            mcqQuestion:data.mcqQuestion,
            videoPreview:data.video,
            videoFile: null,
            correctAnswer: data.correctAnswer,           
            screenType: "VIDEO",
            isCaseStudiesAssessment: false,
            title: data.title
        })
        if(data.isCourseAssessment|| data.isSubModuleAssessment)
            setIsMCQDisabled(false)
          else
            setIsMCQDisabled(true)
            setisloading(false)
    }else{
        setInitialValues({
              isCourseAssessment: false,
              isSubModuleAssessment: false,              
              isMutipleChoice: false,
              mcqQuestion: '',
              mcqOptions: [{ option: '' }],
              correctAnswer: '',
              videoPreview: null,
              screenType: typeOfScreen,
              isCaseStudiesAssessment: false,
              caseStudiesQuestion: '',
              title:''
        })
    }
  },[data])
 
  const validationSchema = Yup.object().shape({
    
    videoFile: Yup.mixed().when('videoPreview', {
        is: (videoPreview) => videoPreview !== null,
        then: Yup.mixed()
          .nullable()
          .test(
            'fileSize',
            'Maximum file size is 500MB',
            // (value) => value && console.log(value)
            (value) => (value && typeof value === 'object' && value.size < 500000000) || true
          ),

        otherwise: Yup.mixed().required('Video is required'),
      }),
      title: Yup.mixed().required('Title is required'),
      mcqQuestion: Yup.string().when(
        ['isMutipleChoice'],
        {
          is: (isMutipleChoice) =>
            (isMutipleChoice === true ),     
          then: Yup.string().trim().required('Required'),
          otherwise: Yup.string().nullable(),
        }
      ),
      correctAnswer: Yup.string().when(
        ['isMutipleChoice', 'mcqOptions'],
        (isMutipleChoice, mcqOptions) => {
          if (
            isMutipleChoice &&           
            mcqOptions
          ) {
            const options = mcqOptions.map(option => option.option);
            return Yup.string().oneOf(
              options,
              'Correct Answer must match one of the options'
            ).required('Correct Answer is required');
          }
          return Yup.string().nullable();
        }
      ),   
     
});

return (
    <>
        <Modal
        open={modalOpen}
        toggle={handleModalClose}
        >
            <Typography>{typeOfScreen}</Typography>
            {isloading ? (<CircularProgress />) :(
            <Formik
            enableReinitialize
            initialValues={initialValues}           
            validationSchema={validationSchema}
            onSubmit={(values) => {
                console.log("Before submit",values)               
                if(values.isCourseAssessment || values.isSubModuleAssessment){
                    if(!values.isMutipleChoice){
                        alert("Multiple Choice should be selected")
                        return
                    }
                    if (values.mcqOptions.length < 3)
                        {
                         // Check if there are at least three options for MCQ
                         alert("Please select at least three options before proceeding.");                    
                         return; 
                    }
                    if (values.mcqOptions.some(option => option.option.trim() === '')) 
                        {
                          // Check if all options are filled for MCQ
                          alert("Please fill out all options before proceeding.");                          
                          return; 
                    }
                }
                
                handleSubmitForm(values);                
            }}>                
                
                {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
                    
                    
                    <Form>
                        
                        <Grid item xs={12} sm={12}>
                                <Typography className={classes.background} variant="subtitle1" gutterBottom>
                                  Screen Title
                                </Typography>
                                <TextField
                                  className={classes.dropzone}
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  name="title"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="text"
                                  value={values.title}
                                  error={Boolean(touched.title && errors.title)}
                                  helperText={touched.title && errors.title}
                                />
                            
                            {values.videoPreview === null ? (
                            <FormControl
                            required
                            component="fieldset"
                            color="primary"
                            variant="outlined"
                            fullWidth
                            name="videoFile"
                            value={values.videoFile}
                            >
                            <Typography variant="subtitle1">Video (Required)</Typography>
                            <DropzoneArea
                                acceptedFiles={['video/*']}
                                showPreviews={false}
                                dropzoneText="Drag and Drop Video or Browse File"
                                showPreviewsInDropzone={false}
                                maxFileSize={104857600}
                                filesLimit={1}
                                showAlerts={false}
                                value={values.videoFile}
                                onChange={(file) => {
                                setFieldValue('videoFile', file[0]);
                                if (file[0]?.size < 500000000) {
                                    const url = URL.createObjectURL(file[0]);
                                    setFieldValue('videoPreview', url);
                                }
                                }}
                                useChipsForPreview
                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                showFileNamesInPreview
                            />
                            <FormHelperText style={{ color: '#F44336' }}>
                                {touched.videoPreview && errors.videoFile}
                            </FormHelperText>
                            </FormControl>
                        ) : (
                            <div className={classes.imgPreviewRoot}>
                            <Typography variant="subtitle1">Video</Typography>
                            <Badge
                                // sx={{ paddingLeft: '3rem' }}
                                badgeContent={
                                <Box sx={{ paddingLeft: '2rem !important' }}>
                                    <Tooltip title="Change video" arrow placement="top">
                                    <IconButton
                                        sx={{ marginLeft: '1.5rem' }}
                                        size="small"
                                        onClick={() => {
                                        setFieldValue('videoPreview', null);
                                        }}
                                        color="primary"
                                    >
                                        <CloseIcon fontSize="small" className={classes.badgeAlign} />
                                    </IconButton>
                                    </Tooltip>

                                    {buttonText === 'Update' && isContainVideo && (
                                    <Tooltip title="Delete video" arrow placement="top">
                                        <IconButton
                                        size="small"
                                        onClick={() => {
                                            handleVideoDelete().then((res) => {
                                            if (res) {
                                                setFieldValue('videoPreview', null);
                                                setIsContainVideo(false);
                                            }
                                            });
                                        }}
                                        color="primary"
                                        >
                                        <DeleteOutlineIcon fontSize="small" className={classes.badgeAlign} />
                                        </IconButton>
                                    </Tooltip>
                                    )}
                                </Box>
                                }
                            >
                                 <video src={values.videoPreview} type="video/mp4" width="320" controls>
                                    <track src="captions_en.vtt" kind="captions" label="English captions" />
                                 </video>
                            </Badge>
                            <Typography sx={{ fontSize: '0.75rem !important' }} color="red">
                                {touched.videoPreview && errors.videoFile}
                            </Typography>
                            </div>
                        )}
                        </Grid>
                        <Box sx={{ mt: 2, padding: '0 8px !important' }}>
                                <Typography variant="subtitle2" className={classes.subtitle}>
                                Assessment
                                </Typography>
                                <Grid item xs="12" sm={12}>

                                <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} sx={{ paddingTop: '4px !important' }}>
                                    <FormControl component="fieldset" color="secondary">
                                    <FormGroup value={values.isCourseAssessment}>
                                        <FormControlLabel
                                        value={values.isCourseAssessment}
                                        checked={values.isCourseAssessment}
                                        color="secondary"
                                        control={
                                            <Checkbox
                                            color="secondary"
                                            onChange={(e, value) => {
                                                setFieldValue('isCourseAssessment', value);
                                                if(values.isSubModuleAssessment || value){
                                                setIsMCQDisabled(false);                                                  
                                                }
                                                else
                                                setIsMCQDisabled(true);
                                                }
                                            }
                                            name="isCourseAssessment"
                                            />
                                        }
                                        label={
                                            <Typography color={'secondary'} variant="body1">
                                            Course Assessment
                                            </Typography>
                                        }
                                        />
                                    </FormGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} sx={{ paddingTop: '4px !important' }}>
                                    <FormControl component="fieldset" color="secondary">
                                    <FormGroup value={values.isSubModuleAssessment}>
                                        <FormControlLabel
                                        value={values.isSubModuleAssessment}
                                        checked={values.isSubModuleAssessment}
                                        color="secondary"
                                        control={
                                            <Checkbox
                                            color="secondary"
                                            onChange={(e, value) => {
                                                setFieldValue('isSubModuleAssessment', value);
                                                if(values.isCourseAssessment || value){
                                                setIsMCQDisabled(false);
                                                }
                                                else
                                                setIsMCQDisabled(true);
                                                }
                                            }
                                            name="isSubModuleAssessment"
                                            />
                                        }
                                        label={
                                            <Typography color={'secondary'} variant="body1">
                                            Sub-Module Assessment
                                            </Typography>
                                        }
                                        />
                                    </FormGroup>
                                    </FormControl>
                                </Grid>
                                </Grid>                            


                                <div style={{display:'flex',marginTop:'20px'}}>
                                <div>
                                <p style={{justifyContent: 'space-between', marginRight:'20px'}}>Include screen with assessment</p>
                                </div>
                                <div>
                                <FormControl component="fieldset">
                                <RadioGroup name="yesNo" value={selectedValue} onChange={handleradioChange}>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                                </FormControl>
                                </div>
                                </div>
                                
                                <Grid item xs={6} sx={{ paddingTop: '4px !important' }} style={{ marginTop: '30px' }}>
                                    <FormControl component="fieldset" color="secondary">
                                        <FormGroup value={values.isMutipleChoice}>
                                        <FormControlLabel
                                            value={values.isMutipleChoice}
                                            checked={values.isMutipleChoice}
                                            color="secondary"
                                            control={
                                            <Checkbox
                                                color="secondary"
                                                onChange={(e, value) => {
                                                
                                                console.log("Calling MCQ checkbox",value,values.mcqQuestion,values.correctAnswer,values.mcqOptions);
                                                setFieldValue('isMutipleChoice', value);
                                                
                                                }}
                                                checked={values.isMutipleChoice}
                                                name="isMutipleChoice"
                                                disabled={isMCQDisabled}
                                            />
                                            }
                                            label={
                                            <Typography color={'secondary'} variant="body1">
                                                Multiple Choice
                                            </Typography>
                                            }
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    </Grid>

                                 

                                    {/* Conditionally render the question and mcqOptions if the "Multiple Choice" checkbox is checked */}
                                    {values.isMutipleChoice && !isMCQDisabled && (
                                      <>
                                        <Grid item xs="12" mt={1}>
                                          <Typography className={classes.background} gutterBottom variant="subtitle1">
                                            MCQ Question*
                                          </Typography>
                                            {/* <TextField
                                              fullWidth
                                              multiline   
                                              rows={3} 
                                              size="small"
                                              name="mcqQuestion"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              type="text"
                                              value={values.mcqQuestion}
                                              variant="outlined"
                                              error={Boolean(touched.mcqQuestion && errors.mcqQuestion)}
                                              helperText={touched.mcqQuestion && errors.mcqQuestion}
                                            /> */}

                                        <ReactQuill
                                          fullWidth
                                          name="mcqQuestion"
                                          // onChange={handleChange}
                                          onChange={(content, delta, source, editor) => {
                                            const isEmpty = !content.trim().replace(/<[^>]*>/g, '').length;
                                              setFieldValue('mcqQuestion', isEmpty ? '':content);
                                          }}
                                          value={values.mcqQuestion}
                                          error={Boolean(touched.mcqQuestion && errors.mcqQuestion)}
                                          helperText={touched.mcqQuestion && errors.mcqQuestion}
                                          modules={modules}
                                          formats={formats}
                                          bounds={".app"}
                                        />
                                        <FormHelperText style={{ color: '#F44336' }}>{errors.mcqQuestion}</FormHelperText>
                                          {/* )} */}
                                        </Grid>
                                        <FieldArray name="mcqOptions">
                                          {({ insert, remove, push }) => (
                                            <div>
                                              {/* {console.log("Values", values.mcqOptions, initialValues.mcqOptions)} */}
                                              {values.mcqOptions!=null && values.mcqOptions.length > 0 &&
                                                values.mcqOptions.map((opt, index) => (  
                                                  <div style={{ position: 'relative', marginTop: '10px' }} key={index}>
                                                    {/* <TextField
                                                        style={{ marginLeft: '20px', marginTop: '10px' }}
                                                        halfwidth
                                                        multiline  
                                                        rows={3}
                                                        className="input-field"
                                                        name={`mcqOptions.${index}.option`}
                                                        placeholder="Option"
                                                        value={opt.option}
                                                        onChange={handleChange}
                                                        type="text"
                                                      /> */}
                                                    <ReactQuill
                                                      halfwidth
                                                      style={{marginTop: '25px',marginLeft:'25px' }}
                                                      name={`mcqOptions.${index}.option`}
                                                      // onChange={handleChange}
                                                      onChange={(content, delta, source, editor) => {
                                                        const isEmpty = !content.trim().replace(/<[^>]*>/g, '').length;
                                                        setFieldValue(`mcqOptions.${index}.option`, isEmpty ? '':content);
                                                      }}
                                                      // onBlur={handleBlur}
                                                      value={opt.option}
                                                      placeholder="Option"
                                                      modules={modules}
                                                      formats={formats}
                                                      bounds={".app"}
                                                    />
                                                      <IconButton aria-label="delete" color="error"
                                                      style={{ position: 'absolute',top:'-30px', right: '-10px' }}
                                                        onClick={() => remove(index)}>
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                  </div>
                                                ))}

                                              <button
                                                type="button"
                                                style={{
                                                  backgroundColor: 'rgb(63, 186, 150)',
                                                  color: 'white',
                                                  border: 'none',
                                                  borderRadius: '6px',
                                                  padding: '10px 20px',
                                                  margin: '10px',
                                                  cursor: 'pointer',
                                                }}
                                                onClick={async () => {
                                                  if (values.mcqOptions.every(option => option.option.trim())) {
                                                    await new Promise((r) =>
                                                      setTimeout(() => {
                                                        push({ option: ""});
                                                        r();
                                                      }, 500)
                                                    );
                                                  } else {
                                                    alert("Please enter options before adding a new one.");
                                                  }
                                                }}
                                              >
                                                Add Option
                                              </button>

                                         <Grid item xs="12" mt={1}>
                                          <Typography className={classes.background} gutterBottom variant="subtitle1">
                                            Correct Answer
                                          </Typography>
                                          {/* {codeScreenType?.code === 'SEQUEL' ? (
                                            <>
                                              <Editor
                                                initialValue={values.correctAnswer}
                                                onEditorChange={(content) => {
                                                  setFieldValue('correctAnswer', content);
                                                }}
                                                onChange={(content, editor) => {
                                                  setFieldValue('correctAnswer', content);
                                                }}
                                                apiKey="2igkw7vsod1gv1x1nmtpl84hoge3yy7olneyw0i3mvw7gu73"
                                                onInit={(evt, editor) => {
                                                  editorRef.current = editor;
                                                }}
                                                init={{
                                                  selector: 'textarea#basic-example',
                                                  height: 200,
                                                  plugins: editorPlugin,
                                                  toolbar: toolbarStyle,
                                                  content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }',
                                                }}
                                              />

                                              <FormHelperText style={{ color: '#F44336' }}>
                                                {errors.mcqQuestionValue}
                                              </FormHelperText>
                                            </>
                                          ) : ( */}
                                            {/* <TextField
                                              halfwidth
                                              multiline  
                                              rows={3}
                                              size="small"
                                              name="correctAnswer"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              type="text"
                                              value={values.correctAnswer}
                                              variant="outlined"
                                              error={Boolean(touched.correctAnswer && errors.correctAnswer)}
                                              helperText={touched.correctAnswer && errors.correctAnswer}

                                            /> */}
                                              <ReactQuill
                                                fullWidth
                                                name="correctAnswer"
                                                // onChange={handleChange}
                                                onChange={(content, delta, source, editor) => {
                                                  setFieldValue('correctAnswer', content);
                                                }}
                                                // onBlur={handleBlur}
                                                value={values.correctAnswer}
                                                error={Boolean(touched.correctAnswer && errors.correctAnswer)}
                                                helperText={touched.correctAnswer && errors.correctAnswer}
                                                modules={modules}
                                                formats={formats}
                                                bounds={".app"}
                                              />
                                                <FormHelperText style={{ color: '#F44336' }}>{errors.correctAnswer}</FormHelperText>
                                        </Grid>
                                            </div>
                                          )}
                                        </FieldArray>
                                        
                                    </>
                                    )}
                                <Box textAlign="right">

                                <LoadingButton
                                color="primary"
                                variant="contained"
                                type="submit"
                                loading={loading}
                                sx={{ marginTop: '1rem' }}
                                onSubmit={async (values) => {
                                    if (!isMCQDisabled && values.isMutipleChoice) 
                                    { // Check if Multiple Choice is selected
                                    if (values.mcqOptions.some(option => option.option.trim() === '')) 
                                    {
                                        // Check if all options are filled for MCQ
                                        alert("Please fill out all options before proceeding.");
                                        return; 
                                    }
                                    if (values.mcqOptions.length < 3)
                                        {
                                        // Check if there are at least three options for MCQ
                                        alert("Please select at least three options before proceeding.");
                                                                         
                                    }
                                    }
                                    
                                }}
                                >
                                {formType === 'EDIT' ? 'Update' : 'Create'}
                                </LoadingButton>

                                </Box>
                            </Grid>
                        </Box>
                    </Form>
                    
                )}
                
            </Formik>
            )}
        </Modal>
    </>
  );
}

const useStyles = makeStyles(() => ({
    subtitle: {
        borderBottom: '1px solid #E4E4E4',
        margin: '-16px',
        padding: ' 8px 30px',
      },

}))

export default VideoScreen