import client from './apiClient';

const getLookUpData = (key) => client.get(`/lookup?key=${key}`);

const getSubcategoryLookUp = (key, code) => client.get(`/lookup?key=${key}&&code=${code}`);

const getLookUpStateData = (key, id) => client.get(`/lookup?key=${key}&Id=${id}`);

const getAuthors = () => client.get(`/user/getAuthors`);

const getClientList = () => client.get(`/client`);

const getIndividualList = (page, pageCount, sortOrder, sort, search) =>
  client.get(
    `/individual/user?page=${page}&pageCount=${pageCount}&sortOrder=${sortOrder}&sort=${sort}&search=${search}`
  );

  const getReferenceFileDetails = (courseSubModuleId,submodulename) => client.get(`/course/submodule/getRefFile?courseSubModuleId=${courseSubModuleId}&submodulename=${submodulename}`)
  
const getCourseLists = (page, search, pageCount) =>
  client.get(`/course?&page=${page}&search=${search}&pageCount=${pageCount}`);

const deleteCourseVideo = (id) => client.delete(`/course/video?courseId=${id}`);

const getCourseDetailsById = (courseId) => client.get(`/course?courseId=${courseId}`);

const getCourseDetailsByIdOne = (clientId) => client.get(`/client?clientId=${clientId}`);

const getCourseContent = (id) => client.get(`/course?courseId=${id}`);

const getCourseModule = (courseId) => client.get(`/course/module/submodule?courseId=${courseId}`);

const deleteCourseModule = (courseId, moduleId) =>
  client.delete(`/course/module?courseId=${courseId}&&courseModuleId=${moduleId}`);

const getCourseModuleTopics = (subModuleId) =>
  client.get(`/course/subModuleTopics/screen?courseSubModuleId=${subModuleId}`);

const editCourseDetailsByIdOne = (clientId, data) => client.put(`/client?clientId=${clientId}`, data);

const editCourseModuleName = (courseId, moduleId, data) =>
  client.put(`/course/module?courseId=${courseId}&&courseModuleId=${moduleId}`, data);

const editCourseSubModuleName = (moduleId, submoduleId, data) =>
  client.put(`/course/subModule?courseModuleId=${moduleId}&&courseSubModuleId=${submoduleId}`, data);

const createCourse = (data) => client.post(`/course`, data);

const editCourseDetails = (courseId, data) => client.put(`/course?courseId=${courseId}`, data);

const createCourseModule = (courseId, data) => client.post(`/course/module?courseId=${courseId}`, JSON.stringify(data));

const createCourseSubModule = (moduleId, data) => client.post(`/course/subModule?courseModuleId=${moduleId}`, data);

const createCourseTopic = (subModuleId, data) =>
  client.post(`/course/subModuleTopics?courseSubModuleId=${subModuleId}`, JSON.stringify(data));

const deleteSubModules = (moduleId, subModuleId) =>
  client.delete(`/course/subModule?courseModuleId=${moduleId}&&courseSubModuleId=${subModuleId}`);

const editSubModuleTopicName = (moduleId, topicId, data) =>
  client.put(
    `/course/subModuleTopics?courseSubModuleId=${moduleId}&&courseSubModuleTopicId=${topicId}`,
    JSON.stringify(data)
  );

const deleteSubModuleTopic = (moduleId, topicId) =>
  client.delete(`/course/subModuleTopics?courseSubModuleId=${moduleId}&&courseSubModuleTopicId=${topicId}`);

const createIntroScreenDetails = (topicId, data) => client.post(`/course/screen/intro?topicId=${topicId}`, data);

const updateIntroScreenDetails = (topicId, screenId, data) =>
  client.put(`/course/screen/intro?topicId=${topicId}&&screenId=${screenId}`, data);

const updateActionScreenDetails = (topicId, screenId, data, courseId) =>
  client.put(`/course/screen/action?topicId=${topicId}&&screenId=${screenId}&courseId=${courseId}`, data);

const updateVideoScreenDetails = (topicId, screenId, data, courseId) =>
  client.put(`/course/screen/video?topicId=${topicId}&&screenId=${screenId}&courseId=${courseId}`, data);

const updateSuccessScreenDetails = (topicId, screenId, data) =>
  client.put(`/course/screen/success?topicId=${topicId}&&screenId=${screenId}`, data);

const createActionScreenDetails = (topicId, data, courseId) =>
  client.post(`/course/screen/action?topicId=${topicId}&courseId=${courseId}`, data);

const createVideoScreenDetails = (topicId, data, courseId) =>
  client.post(`/course/screen/video?topicId=${topicId}&courseId=${courseId}`, data);

const createSuccessScreenDetails = (topicId, data) => client.post(`/course/screen/success?topicId=${topicId}`, data);

const getIntroScreenDetailsById = (topicId, screenId) =>
  client.get(`/course/screen/intro?topicId=${topicId}&&screenId=${screenId}`);

const getActionScreenDetailsById = (topicId, screenId, actionType) =>
  client.get(`/course/screen/action?topicId=${topicId}&&screenId=${screenId}&&actionType=${actionType}`);

const getVideoScreenDetailsById = (topicId, screenId) =>
  client.get(`/course/screen/video?topicId=${topicId}&&screenId=${screenId}`);

const getSuccessScreenDetailsById = (topicId, screenId) =>
  client.get(`/course/screen/success?topicId=${topicId}&&screenId=${screenId}`);

const deleteScreenById = (topicId, screenId) =>
  client.delete(`/course/screen/?topicId=${topicId}&&screenId=${screenId}`);

const postClientDetails = (data) => client.post(`/client`, data);

const editClientDetailsByID = (clientId, data) => client.put(`/client?clientId=${clientId}`, data);

const getClientDetalsById = (clientId) => client.get(`/client?clientId=${clientId}`);

const getDashBoardValues = () => client.get(`/dashboard`);

const getDashBoardChart = (fromDate, toDate) =>
  client.get(`/dashboard/individual/userDetails?fromDate=${fromDate}&toDate=${toDate}`);

const getLookUpDetails = (key) => client.get(`/lookup?key=${key}`);

const deleteClientDetailsByID = (clientId) => client.delete(`/client?clientId=${clientId}`);

const postCreateAdmin = (clientId, data) => client.post(`/client/user?clientId=${clientId}`, data);

const getClientInfo = (clientId) => client.get(`/client?clientId=${clientId}`);

const getusers = (clientId) => client.get(`/client/users?clientId=${clientId}`);

const deleteUserDetails = (clientId, userId) => client.delete(`/client/user?clientId=${clientId}&&userId=${userId}`);

const handleClientStatus = (clientId, data) =>
  client.patch(`/client/clientStatus?clientId=${clientId}`, JSON.stringify(data));

const handleUserStatus = (clientId, userId, data) =>
  client.patch(`/client/userStatus?clientId=${clientId}&&userId=${userId}`, JSON.stringify(data));

const profileUpdate = (data) => client.put('/authenticate/profile', data);

const getSubscriptionData = (clientId) => client.get(`/subscription/details?clientId=${clientId}`);

const postSubsrciptionplan = (clientId, data) =>
  client.post(`/subscription/client?clientId=${clientId}`, JSON.stringify(data));

const editSubsrciptionplan = (id, data) => client.put(`/subscription/client?id=${id}`, JSON.stringify(data));

const deleteSubsrciptionplanByID = (id) => client.delete(`/subscription/client?id=${id}`);

const getSubscriptionCoursePlansByID = (id) => client.get(`/subscription/client?id=${id}`);

const handleSubscriptionStatus = (subscriptionCoursePlanId, data) =>
  client.patch(`/subscription/coursePlan?subscriptionCoursePlanId=${subscriptionCoursePlanId}`, JSON.stringify(data));

const deleteActionScreenKeys = (id) => client.delete(`/course/screen/action/key?keyId=${id}`);

const createActionScreenKeys = (topicId, screenId, data) =>
  client.post(`/course/screen/action/key?topicId=${topicId}&&screenId=${screenId}`, data);

const createActionScreenKeysForCaseStudy = (screenId, data) =>
  client.post(`/course/screen/action/key?screenId=${screenId}&&topicId`, data);

const deleteCourseById = (id) => client.delete(`/course?courseId=${id}`);

const updateModulePositions = (courseId, data) =>
  client.put(`/course/module/positionIndex?courseId=${courseId}`, JSON.stringify(data));

const updateTopicPositions = (id, data) =>
  client.put(`/course/subModuleTopics/positionIndex?courseSubModuleId=${id}`, JSON.stringify(data));

const updateCourseStatus = (courseId, data) =>
  client.patch(`/course/status?courseId=${courseId}`, JSON.stringify(data));

const getCourseStatus = (key) => client.get(`/lookup?key=${key}`);

const updateSubModulePositions = (moduleId, data) =>
  client.put(`/course/subModule/positionIndex?courseModuleId=${moduleId}`, JSON.stringify(data));

const updateSubModuleParent = (from , to, submoduleId) =>
    client.put(`/course/subModule/updateSubModuleParent?from=${from}&&to=${to}&&submoduleId=${submoduleId}`)

const updateScreenPositions = (topicId, data) =>
  client.put(`/course/screen/positionIndex?topicId=${topicId}`, JSON.stringify(data));

const courseTrial = (data) => client.post(`/course/trial`, data);

const getAdminUserList = (courseId, clientId) => client.get(`/lookup/users?courseId=${courseId}&clientId=${clientId}`);

const enrollUserToCourse = (clientId, courseId, data) =>
  client.post(`/user/enroll?clientId=${clientId}&courseId=${courseId}`, JSON.stringify(data));

const enrollNewUser = (clientId, courseId, data) =>
  client.post(`/user/course/enroll?clientId=${clientId}&courseId=${courseId}`, JSON.stringify(data));
const handleDeleteModuleVideo = (id) => client.delete(`/course/module/video?courseModuleId=${id}`);

const getChatResult = (question) => {
  return client.post(`/user/getChatResponse?question=${question}`, { question });
};

const handleDeleteSubmoduleVideo = (id) => client.delete(`/course/subModule/video?subModuleId=${id}`);

const handleDeleteScreenAudio = (id) => client.delete(`/course/screen/audio?screenId=${id}`);
const getModuleDataValidation=(moduleId)=>client.patch(`/course/mstatus?moduleId=${moduleId}`);

const getEnrolledUser = (courseId, clientId, clientPlanId) =>
  client.get(`/user/enroll?courseId=${courseId}&clientId=${clientId}&clientPlanId=${clientPlanId}`);

const deleteEnrolledUser = (planId, courseId) => client.delete(`/user/enroll?planId=${planId}&userId=${courseId}`);

const postCloningCourses = (courseId, ownerId, authors, data) =>
  client.post(`/course/clone?courseId=${courseId}&authors=${authors}&userId=${ownerId}`, JSON.stringify(data));

const importModules = (courseId, fromCourseId,ownerId, moduleData) =>
  client.post(`/course/import?tocourseId=${courseId}&fromcourseId=${fromCourseId}&authuserid=${ownerId}`, JSON.stringify(moduleData));

const createReference=(moduleId,subModuleName,data)=>
  client.post(`/course/submodule/addRefFile?courseModuleId=${moduleId}&subModuleName=${subModuleName}`,data);
const editCourseSubModuleRef = (moduleId, subModuleName, data) =>
  client.put(`/course/subModule/editRef?courseModuleId=${moduleId}&&subModuleName=${subModuleName}`, data);
const deletreferenceDetails=(subModuleId,referenceId)=>
  client.delete(`course/subModule/deleteRef?subModuleId=${subModuleId}&referenceid=${referenceId}`);

const updateisCompleteResult =(data) =>
  client.post(`user/videoResult`, data);

export default {
  updateisCompleteResult,
  getModuleDataValidation,
  getLookUpData,
  getSubcategoryLookUp,
  getClientList,
  getIndividualList,
  createCourse,
  getCourseLists,
  deleteCourseVideo,
  getChatResult,
  getCourseContent,
  getCourseModule,
  getCourseDetailsById,
  getReferenceFileDetails,
  getCourseModuleTopics,
  deleteCourseModule,
  editCourseDetails,
  createCourseModule,
  editCourseModuleName,
  editCourseSubModuleName,
  createCourseSubModule,
  createCourseTopic,
  deleteSubModules,
  editSubModuleTopicName,
  createActionScreenDetails,
  createVideoScreenDetails,
  createSuccessScreenDetails,
  getVideoScreenDetailsById,
  getActionScreenDetailsById,
  getIntroScreenDetailsById,
  getSuccessScreenDetailsById,
  deleteScreenById,
  deleteSubModuleTopic,
  getLookUpStateData,
  createIntroScreenDetails,
  postClientDetails,
  editClientDetailsByID,
  getClientDetalsById,
  getLookUpDetails,
  deleteClientDetailsByID,
  postCreateAdmin,
  getClientInfo,
  getusers,
  deleteUserDetails,
  updateIntroScreenDetails,
  updateVideoScreenDetails,
  updateSuccessScreenDetails,
  handleClientStatus,
  handleUserStatus,
  profileUpdate,
  getSubscriptionData,
  postSubsrciptionplan,
  deleteSubsrciptionplanByID,
  editSubsrciptionplan,
  getSubscriptionCoursePlansByID,
  updateActionScreenDetails,
  handleSubscriptionStatus,
  getCourseDetailsByIdOne,
  editCourseDetailsByIdOne,
  deleteActionScreenKeys,
  createActionScreenKeys,
  deleteCourseById,
  getDashBoardValues,
  getDashBoardChart,
  updateModulePositions,
  updateTopicPositions,
  updateCourseStatus,
  getCourseStatus,
  updateSubModulePositions,
  updateSubModuleParent,
  updateScreenPositions,
  createActionScreenKeysForCaseStudy,
  courseTrial,
  postCloningCourses,
  getAdminUserList,
  enrollUserToCourse,
  enrollNewUser,
  handleDeleteModuleVideo,
  handleDeleteSubmoduleVideo,
  getEnrolledUser,
  deleteEnrolledUser,
  handleDeleteScreenAudio,
  getAuthors,
  importModules,
  createReference,
  editCourseSubModuleRef,
  deletreferenceDetails
};
