import React, { useRef, useState,useEffect  } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faRedo, faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import { Typography } from '@mui/material';

const VideoPlayer = ({ videoUrl, onClickCallBack }) => {
  const videoRef = useRef(null);
  const [showControls, setShowControls] = useState(false);
  const [showError, setShowError] = useState(false);
  const [videoHeight, setVideoHeight] = useState('auto');


  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const checkVideoStatus = () => {
      if (videoElement.videoWidth === 0 && videoElement.videoHeight === 0) {
        setShowError(true);
      } else {
        setShowError(false);
        
      }
    };

    videoElement.addEventListener('loadedmetadata', checkVideoStatus);

    return () => {
      videoElement.removeEventListener('loadedmetadata', checkVideoStatus);
    };
  }, []);
 

  const handleForward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10; // Forward 10 seconds
    }
  };

  const handleRewind = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10; // Rewind 10 seconds
    }
  };

  const handleReplay = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Replay from the beginning
      videoRef.current.play(); // Start playing after replay
    }
  };

  const handleMouseEnter = () => {
    setShowControls(true);
  };

  const handleMouseLeave = () => {
    setShowControls(false);
  };

  const onVideoEnd = async () => {
   
    onClickCallBack()
    
  }

  return (
    <div
      style={{ position: 'relative', width: '100%', height: '75%'}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showError ? (
        <div style={{ position: 'absolute', paddingTop: '10%',paddingLeft:'10%', textAlign: 'center' }}>
          <div> Oops! Something went wrong while trying to play the video. Please try again later or switch browser</div>
        </div>
      ) : (
        <>
          <video
            ref={videoRef}
            src={videoUrl}
            onEnded={onVideoEnd}
            controls
            controlsList='nodownload'
            width="100%"
            height="500px"
            disablePictureInPicture // Disable picture-in-picture mode
            autoPlay
          >
            <track kind="captions" src="" />
          </video>
          {showControls && (
            <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
              <button type="button" onClick={handleRewind} style={{ marginRight: '10px', border: 'none', background: 'transparent' }}>
                <FontAwesomeIcon icon={faBackward} style={{ fontSize: '14px', color: 'grey' }} />
              </button>
              <button type="button" onClick={handleReplay} style={{ marginRight: '10px', border: 'none', background: 'transparent' }}>
                <FontAwesomeIcon icon={faUndo} style={{ fontSize: '14px', color: 'grey' }} />
              </button>
              <button type="button" onClick={handleForward} style={{ border: 'none', background: 'transparent' }}>
                <FontAwesomeIcon icon={faForward} style={{ fontSize: '14px', color: 'grey' }} />
              </button>
            </div>
          )}
        </>
      )} 
    </div>    
  );
};

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired, 
};

export default VideoPlayer;
