import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, IconButton, Paper, Typography, TextField, Tooltip, FormHelperText } from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
import { useNavigate, useLocation, NavLink, Link as RouterLink } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import draftToHtml from 'draftjs-to-html';
import { convertFromRaw, EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import * as Yup from 'yup';
import { Form, Field, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import CaseStudies from '../../../services/caseStudies/CaseStudies';

export default function CreateCourseContent(props) {
  console.log(props,"propsprops");
  const classes = useStyles();
  const location = useLocation();
  const courseId = location.state;
  const [testTime, setTestTime] = useState([]);
  const [marksType, setMarksType] = useState([]);

  const [loading, setLoading] = React.useState(false);

  const content = {
    blocks: [
      {
        key: '637gr',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  };

  const contents = {
    blocks: [
      {
        key: '637gr',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  };
  const contentBlock = convertFromRaw(content);
  const contentBlocks = convertFromRaw(contents);

  const numbers = [{ label: '1' }, { label: '2' }, { label: '3' }, { label: '4' }, { label: '5' }];

  const [initialValues, setInitialValues] = useState({
    name: 'CaseStudies',
    descContent: '',
    descContents: '',
    startMessage: EditorState.createWithContent(contentBlock),
    endMessage: EditorState.createWithContent(contentBlocks),
    startMsgContent: '',
    endMsgContent: '',
    noOfInputs: numbers?.[0],
    displayResult: false,
    enterPassMarks: null,
    certificate: false,
    seconds: '',
    time: '',
    marksType: '',
    templateName: '',
  });

  const templateName = [
    { code: 'Generic Template', description: 'Generic Template' },
    { code: 'Excel Efficiency CPD Logo', description: 'Excel Efficiency CPD Logo' },
  ];

  const getLookup = async () => {
    try {
      const response = await CaseStudies.lookup('testTime');
      if (response.ok) {
        setTestTime(response.data.testTime);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const MarksTypeLookup = async () => {
    try {
      const response = await CaseStudies.MarksTypeLookup('marks');
      if (response.ok) {
        setMarksType(response.data.marks);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitValues = async (values) => {
    setLoading(true);
    const arr = values.seconds.split(':'); // splitting the string by colon
    const seconds = parseInt(arr[0], 10) * 60 + parseInt(arr[1], 10); // converting

    const data = {
      name: 'CaseStudies',
      numOfAttempts: Number(values.noOfInputs.label),
      timeDuration: seconds.toString(),
      timeTestType: values.time,
      passMarks: values.enterPassMarks.toString(),
      sendCertificate: values.certificate,
      displayResult: values.displayResult,
      marksType: values.marksType.code,
      templateName: values.templateName.description,
      startMessage: values.startMsgContent,
      endMessage: values.endMsgContent,
    };
    if (props.type === 'EDIT') {
      try {
        const response = await CaseStudies.UpdateConfigurationForCaseStudies(props.configurationId, data);
        if (response.ok) {
          props.getConfigurationForCaseStudies();

          props.snackBarControl(response.data.message);
          props.toggle();
          setLoading(false);
        } else {
          props.getConfigurationForCaseStudies();

          props.snackBarControl(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await CaseStudies.CreateConfigurationForCaseStudies(courseId, data);
        if (response.ok) {
          props.getConfigurationForCaseStudies();

          props.snackBarControl(response.data.message);
          props.toggle();
          setLoading(false);
        } else {
          setLoading(false);

          props.snackBarControl(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    let minsec;
    getLookup();
    MarksTypeLookup();
    if (props.type === 'EDIT') {
      const startMessages = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(props.data.testStartMessage))
      );
      const endMessages = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(props.data.testEndMessage))
      );

      const minutes = parseInt(props.data.timeduration / 60, 10) % 60;
      const seconds = props.data.timeduration % 60;

      if (minutes.toString().length === 1 && seconds.toString().length === 1) {
        const min = `${0}${minutes}`;
        const sec = `${0}${seconds}`;
        minsec = `${min}:${sec}`;
      } else if (seconds.toString().length === 1) {
        const sec = `${0}${seconds}`;
        minsec = `${minutes}:${sec}`;
      } else if (minutes.toString().length === 1) {
        const min = `${0}${minutes}`;
        minsec = `${min}:${seconds}`;
      } else {
        minsec = `${minutes}:${seconds}`;
      }

      const data = {
        name: props.data.name,
        startMessage: startMessages,
        endMessage: endMessages,
        startMsgContent: props.data.testStartMessage,
        endMsgContent: props.data.testEndMessage,
        noOfInputs: { label: props.data.noOfAttempts },
        displayResult: props.data.displayResult,
        enterPassMarks: props.data.passmarks,
        certificate: props.data.sendcertificate,
        seconds: minsec,
        time: props.data.timeTestType,
        marksType: { description: props.data.marksType },
        templateName: { description: props.data.templateName },
      };

      setInitialValues(data);
    }
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          // name: Yup.string().required('Required'),
          enterPassMarks: Yup.number().required('Required').lessThan(100, `You own only ${100}.`).nullable(),
          noOfInputs: Yup.string().required('Required').nullable(),
          displayResult: Yup.bool().required('Required').nullable(),
          certificate: Yup.bool().required('Required').nullable(),
          seconds: Yup.string()
            .required('Required')
            .matches(
              '^(?!00:00|00:2|00:1|00:3|00:4|00:5|00:0)^((?:60:00)|(?:[0-5][0-9]:[0-5][0-9]))$',
              'Time format should be mm:ss, minimum time 1 min'
            ),
          marksType: Yup.string().required('Required').nullable(),
          templateName: Yup.string().required('Required').nullable(),
          time: Yup.string().required('Required').nullable(),
        })}
        onSubmit={(values) => {
          handleSubmitValues(values);
          console.log(values);
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Typography gutterBottom className={classes.certificate}>
                  Test Start Screen (Instructions To User)
                </Typography>
                <Editor
                  editorState={values.startMessage}
                  editorClassName={classes.textEditor}
                  toolbarClassName={classes.toolbarEditor}
                  onEditorStateChange={(_editorState) => {
                    setFieldValue('startMessage', _editorState);
                    setFieldValue('startMsgContent', draftToHtml(convertToRaw(_editorState.getCurrentContent())));
                  }}
                  toolbar={{
                    options: ['inline', 'fontSize', 'colorPicker', 'textAlign'],
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Typography gutterBottom className={classes.certificate}>
                  Test End Message
                </Typography>
                <Editor
                  editorState={values.endMessage}
                  editorClassName={classes.textEditor}
                  toolbarClassName={classes.toolbarEditor}
                  onEditorStateChange={(_editorState) => {
                    setFieldValue('endMessage', _editorState);
                    setFieldValue('endMsgContent', draftToHtml(convertToRaw(_editorState.getCurrentContent())));
                  }}
                  toolbar={{
                    options: ['inline', 'fontSize', 'colorPicker', 'textAlign'],
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Typography gutterBottom className={classes.certificate}>
                  Number of Attempts
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={numbers}
                  disabled
                  fullWidth
                  name="noOfInputs"
                  filterSelectedOptions
                  autoHighlight
                  getOptionLabel={(option) => option.label || ''}
                  value={values.noOfInputs}
                  onChange={(e, value) => {
                    setFieldValue('noOfInputs', value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="noOfInputs"
                      error={Boolean(touched.noOfInputs && errors.noOfInputs)}
                      helperText={touched.noOfInputs && errors.noOfInputs}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid item xs={12} sm={12} md={4} xl={4} lg={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.displayResult}
                        value={values.displayResult}
                        onChange={handleChange}
                        error={Boolean(touched.displayResult && errors.displayResult)}
                        helperText={touched.displayResult && errors.displayResult}
                        id="displayResult"
                        name="displayResult"
                        color="primary"
                      />
                    }
                    label="Display Result"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <FormControl>
                  <FormLabel>
                    <span style={{ fontWeight: 'bold', color: 'black', fontSize: '14px' }}>Time</span>{' '}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="time"
                    onChange={(event) => {
                      setFieldValue('time', event.target.value);
                    }}
                    value={values.time}
                  >
                    {testTime &&
                      testTime.map((item, index) => {
                        console.log(item);
                        return (
                          <FormControlLabel
                            value={item.code}
                            control={<Radio disabled={index === 0} />}
                            label={item.description}
                          />
                        );
                      })}
                  </RadioGroup>
                  <FormHelperText style={{ color: '#F44336' }}>{touched.time && errors.time}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Minutes and seconds"
                  variant="outlined"
                  placeholder="mm:ss"
                  type="text"
                  error={Boolean(touched.seconds && errors.seconds)}
                  fullWidth
                  helperText={touched.seconds && errors.seconds}
                  name="seconds"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.seconds}
                  inputProps={{ maxLength: 128 }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Typography gutterBottom className={classes.certificate}>
                  Certificate
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                <Autocomplete
                  style={{ marginTop: '1.2rem' }}
                  disablePortal
                  id="combo-box-demo"
                  options={marksType}
                  fullWidth
                  name="marksType"
                  filterSelectedOptions
                  autoHighlight
                  getOptionLabel={(option) => option.description || ''}
                  value={values.marksType}
                  onChange={(e, value) => {
                    setFieldValue('marksType', value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="marksType"
                      label="Marks Type"
                      error={Boolean(touched.marksType && errors.marksType)}
                      helperText={touched.marksType && errors.marksType}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                <Autocomplete
                  style={{ marginTop: '1.2rem' }}
                  disablePortal
                  id="combo-box-demo"
                  options={templateName}
                  fullWidth
                  name="templateName"
                  filterSelectedOptions
                  autoHighlight
                  getOptionLabel={(option) => option.description || ''}
                  value={values.templateName}
                  onChange={(e, value) => {
                    setFieldValue('templateName', value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="templateName"
                      label="Select Template "
                      error={Boolean(touched.templateName && errors.templateName)}
                      helperText={touched.templateName && errors.templateName}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <div className={classes.enter}>
                  <div>
                    <Typography gutterBottom>Enter Pass Marks</Typography>
                  </div>
                  <div className={classes.enterPassMarks}>
                    <TextField
                      style={{ width: '60px' }}
                      error={Boolean(touched.enterPassMarks && errors.enterPassMarks)}
                      fullWidth
                      helperText={touched.enterPassMarks && errors.enterPassMarks}
                      name="enterPassMarks"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.enterPassMarks}
                      variant="outlined"
                      inputProps={{ maxLength: 128 }}
                    />

                    <span className={classes.enterPassMarks}>
                      <Typography className={classes.enterPercentage}>%</Typography>{' '}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.certificate}
                        value={values.certificate}
                        onChange={handleChange}
                        error={Boolean(touched.certificate && errors.certificate)}
                        helperText={touched.certificate && errors.certificate}
                        id="certificate"
                        name="certificate"
                        color="primary"
                      />
                    }
                    label="Send Certificate When Completed The Test"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <LoadingButton id='saveButtonId' fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  certificate: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  container: {
    padding: '10px',
  },
  enter: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
  enterPassMarks: {
    marginLeft: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  timeField: {
    width: '300px',
    padding: '10px',
  },
}));

const customeTheme = createTheme({
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          backgroundColor: '#000E3B',
          color: 'white',
          fontWeight: 'bold',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
  },
});
