import React, { useState } from 'react';
import { Box, TextField, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import Draggable from 'react-draggable';
import adminServices from '../../../services/adminServices';



const ChatWindow = ({ open, onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const [chats, setChats] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = async () => {
    const userQuestion = inputValue.trim();

    if (userQuestion) {
      try {
        const response = await adminServices.getChatResult(userQuestion);
        const newChat = { question: userQuestion, answer: response.data.answer };
        setChats([...chats, newChat]);
        setInputValue('');
      } catch (error) {
        console.error("There was an error fetching the chat response:", error);
      }
    }
  };

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleClearChat = () => {
    setChats([]);
  };

  if (!open) return null;

  return (
    <Draggable>
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          width: isMinimized ? '300px' : '400px',
          height: isMinimized ? '50px' : '400px',
          backgroundColor: 'white',
          boxShadow: 3,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          zIndex: 1000
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, backgroundColor: '#3f51b5', color: 'white' }}>
          <Typography variant="h6">Chat</Typography>
          <Box>
            <IconButton size="small" onClick={handleMinimize} style={{ color: 'white' }}>
              <MinimizeIcon />
            </IconButton>
            <IconButton size="small" onClick={onClose} style={{ color: 'white' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {!isMinimized && (
          <Box sx={{ padding: 2, flex: 1, overflowY: 'auto' }}>
            {chats.map((chat, index) => (
              <Box key={index} mb={2}>
                <Typography variant="body1"><strong>Q:</strong> {chat.question}</Typography>
                <Typography variant="body1"><strong>A:</strong> {chat.answer}</Typography>
              </Box>
            ))}
            <TextField
              fullWidth
              multiline
              rows={4}
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type your message here..."
              variant="outlined"
              margin="normal"
            />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Button variant="contained" color="primary" onClick={handleSendMessage}>
                Send
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleClearChat}>
                Clear Chat
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Draggable>
  );
};

export default ChatWindow;
