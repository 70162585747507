import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormControl, Box, Grid, FormHelperText, Badge, Tooltip, IconButton } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import TextField from "@material-ui/core/TextField";
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { Editor } from '@tinymce/tinymce-react';
import * as Yup from 'yup';
import DialogModal from '../../../components/modal/DialogModal';

const AddRererenceModel = ({
    open,
    onClose,
    onSubmit,
    modelTitle,
    handleSubmit,
    buttonText,
    loading,
    data,
    formType,
    formCategory,
    setActionType,
    updateBtnLoading,
    deleteBtnLoading
}) => {    
    const [initialValue, setInitialValue] = useState({
        referenceFile: null,
        referenceFileView: null,
        description: '',
        referenceFileName: null,
        subModuleId:null,
    });
    const [fileError, setFileError] = useState('');
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const editorPlugin = [
        'a11ychecker',
        'advlist',
        'advcode',
        'advtable',
        'autolink',
        'checklist',
        'export',
        'lists',
        'link',
        'image',
        'charmap',
        'preview',
        'anchor',
        'searchreplace',
        'visualblocks',
        'powerpaste',
        'fullscreen',
        'formatpainter',
        'insertdatetime',
        'media',
        'table',
        'help',
        'wordcount',
    ];

    const toolbarStyle =
        'undo redo | casechange blocks | bold italic backcolor | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help';
    const [iscontainedReference, setIsContainReference] = useState(false)
    const classes = useStyles();
    useEffect(() => {
        if (data) {
            let fileName;
            if (data?.referenceFileView !== null) {
                fileName = data.referenceFileView.match(/[^/]+$/)[0]
            }
            // const filename2 = url.match(/[^/]+$/)[0];
            setInitialValue({
                referenceFile: data.referenceFile,
                description: data.referencetext,
                referenceFileView: data.referenceFileView,
                referenceFileName: fileName,
                referenceid: data.referenceid,
                subModuleId:data.subModuleId
            });
            // setIsContainReference(data.referenceFileView && true);
        } else {
            setInitialValue({
                referenceFileView: null,
                referenceFile: null,
                referenceFileName: null,
                description: null,
                referenceid: null
            });
        }
    }, [data]);
    const SUPPORTED_FORMATS = [
        'image/*',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/zip',
        '.zip',
        'application/x-zip-compressed',
        'multipart/x-zip',
        'image/jpeg',
         'image/png',
          'image/gif'
    ]
    const FILE_SIZE = 5 * 1024 * 1024;// 50 MB

    const validationSchema = Yup.object().shape({
        description: Yup.string().nullable().required('Description is required'),
        // referenceFile: Yup.mixed()
        //     .test('required', 'Reference file is required', (value, context) => {
        //         const { referenceFileView } = context.parent;
        //         if (!referenceFileView && !value) {
        //             return false;
        //         }
        //         return true;
        //     })
        //     .test('fileSize', 'Maximum file size is 5MB', (value) => {
        //         if (value && typeof value === 'object') {
        //             return value.size <= FILE_SIZE; // 5 MB 
        //         }
        //         return true;
        //     }),
    });

    return (
        <DialogModal open={open} handleClose={onClose} title={modelTitle}>
            <Formik
                enableReinitialize
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >
                {({ values, touched, errors, handleBlur, handleChange, setFieldValue }) => (
                    <Form>
                        {/* {formType === 'MODULE' && formCategory === 'subModule' && ( */}
                            <Grid item xs={12} sm={12}>
                                {values.referenceFileView === null ? (
                                    <FormControl
                                        required
                                        component="fieldset"
                                        color="primary"
                                        variant="outlined"
                                        fullWidth
                                        name="referenceFile"
                                        value={values.referenceFile}
                                    >
                                        <Typography variant="subtitle1">Reference(optional)</Typography>
                                        <DropzoneArea
                                            acceptedFiles={SUPPORTED_FORMATS}
                                            showPreviews={false}
                                            onBlur={handleBlur("referenceFile")}
                                            dropzoneText="Drag and Drop  Browse File"
                                            showPreviewsInDropzone={false}
                                            maxFileSize={FILE_SIZE}
                                            filesLimit={1}
                                            showAlerts={false}
                                            value={values.referenceFile}
                                            onChange={(file) => {
                                                setFileError('');
                                                setFieldValue('referenceFile', file[0]);
                                                setFieldValue('referenceFileName', file[0]?.name);
                                                if (file[0]?.size <= FILE_SIZE) {
                                                    const url = URL.createObjectURL(file[0]);
                                                    setFieldValue('referenceFileView', url);
                                                }
                                                else {
                                                    setFileError('Maximum file size allowed is 5 MB.');
                                                }
                                            }}
                                            onDropRejected={(rejectedFiles) => {
                                                const fileSizeExceeded = rejectedFiles.some(file => file.size > FILE_SIZE);
                                                const unsupportedFormat = rejectedFiles.some(file => !SUPPORTED_FORMATS.includes(file.type));
                                                
                                                if (fileSizeExceeded && !unsupportedFormat) {
                                                    setFileError('Maximum file size allowed is 5 MB.');
                                                } else if (unsupportedFormat) {
                                                    setFileError('Please upload a supported file.');
                                                }
                                            }}
                                            useChipsForPreview
                                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                            showFileNamesInPreview
                                        />
                                        <FormHelperText style={{ color: '#F44336' }}>
                                            {fileError || touched.referenceFile && errors.referenceFile}
                                        </FormHelperText>
                                    </FormControl>
                                ) : (
                                    <div className={classes.imgPreviewRoot}>
                                        <Typography variant="subtitle1">Reference</Typography>
                                        <Badge
                                            // sx={{ paddingLeft: '3rem' }}
                                            badgeContent={
                                                <Box sx={{ paddingLeft: '2rem !important' }}>
                                                    <Tooltip title="Change Reference" arrow placement="top">
                                                        <IconButton
                                                            sx={{ marginLeft: '1.5rem' }}
                                                            id='closeIconChangeReferenceId'
                                                            size="small"
                                                            onClick={() => {
                                                                setFieldValue('referenceFileView', null);
                                                                setFieldValue('referenceFile', null);
                                                                setFieldValue('referenceFileName', null);
                                                            }}
                                                            color="primary"
                                                        >
                                                            <CloseIcon fontSize="small" className={classes.badgeAlign} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            }
                                        >
                                            {values.referenceFile?.type.startsWith('image/') ? (
                                                <img src={values.referenceFileView}  value={values.referenceFile} alt="Reference File" width="320" />
                                            ) : (
                                                <a href={values?.referenceFileView} value={values.referenceFile} target="_blank" rel="noopener noreferrer" width="100px">
                                                    {values.referenceFileName || 'View File'}
                                                </a>
                                            )}
                                        </Badge>
                                        <FormHelperText style={{ color: '#F44336' }}>
                                        {(fileError && touched.referenceFile) || (touched.referenceFile && errors.referenceFile)}
                                        </FormHelperText>
                                    </div>
                                )}

                            </Grid>
                        {/* )} */}
                        <Grid item xs="12">
                            <Typography className={classes.background} gutterBottom variant="subtitle1">
                                Description*
                            </Typography>
                            {/* <Editor
                                value={values.description}
                                onEditorChange={(content, editor) => {
                                    setFieldValue('description', content);
                                }}
                                apiKey="2igkw7vsod1gv1x1nmtpl84hoge3yy7olneyw0i3mvw7gu73"
                                init={{
                                    selector: 'textarea#basic-example',
                                    height: 200,
                                    plugins: editorPlugin,
                                    toolbar: toolbarStyle,
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                }}
                            /> */}
                             <ReactQuill
                                theme="snow"
                                value={values.description}
                                 id='addReferenceDescriptionId'
                                onChange={(content, editor) => {
                                    const isEmpty = !content.trim().replace(/<[^>]*>/g, '').length;
                                    setFieldValue('description', isEmpty ? '' : content);
                                }}
                            />
                        </Grid>
                        <FormHelperText style={{ color: '#F44336' }}>
                            {touched.description && errors.description}
                        </FormHelperText>
                        {
                            formType === 'createRef' && (
                                <LoadingButton
                                    variant="contained"
                                    id='createButtonIdForReference'
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                    type="submit"
                                    loading={loading}
                                >
                                    Create
                                </LoadingButton>
                            )
                        }
                        {formType === 'editRef' && (
                            <Box style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }} >
                                <LoadingButton
                                    variant="contained"
                                    id='updateButtonIdForReference'
                                    color="primary"
                                    type='submit'
                                    loading={updateBtnLoading}
                                    onClick={() => {
                                        setActionType("update")                                        
                                    }}
                                >
                                    Update
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    id='deleteButtonIdForReference'
                                    color="primary"
                                    type='submit'
                                    loading={deleteBtnLoading}
                                    onClick={() => {
                                        setActionType("delete")
                                    }}
                                >
                                    Delete
                                </LoadingButton>
                            </Box>
                        )}

                    </Form>
                )}
            </Formik>
        </DialogModal>

    )
}
const useStyles = makeStyles((theme) => ({
    class: {
        padding: '2px',
    },
}));
export default AddRererenceModel;