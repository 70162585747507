import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Grid, Typography, Container, Button, IconButton, TextField, Avatar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GridOnIcon from '@mui/icons-material/GridOn';
import ViewListIcon from '@mui/icons-material/ViewList';
import { makeStyles } from '@mui/styles';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

import Page from '../../../components/Page';
import Cards from '../../../components/cards/UserCard';
import PageHeader from '../../../components/PageHeader';
import adminServices from '../../../services/adminServices';
import CardSkeleton from '../../../components/Skeleton/cardSkeleton';

export default function CourseList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [maxCount, setMaxCount] = useState();
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = useState(0);
  const [viewType, setViewType] = useState('table');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleGridClick = () => {
    setViewType('grid');
  };

  const handleTableClick = () => {
    setViewType('table');
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    getCourserList(newPage, searchText, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getCourserList(null, searchText, event.target.value, 10);
    setPage(0);
  };

  const getCourserList = async (page, searchText, rowsPerPage) => {
    const result = await adminServices.getCourseLists(page + 1, searchText, rowsPerPage);
    if (result.ok) {
      // console.log(result);
      // setPage(result.data[0].metadata.currentPage);
      setRowsPerPage(result.data[0].metadata.perPage);
      setMaxCount(result.data[0].metadata.totalRecordCount);
      setCourseList(result.data[0].records);
      setLoading(false);
    }
  };

  // function handleClick(event) {
  //   event.preventDefault();
  //   console.info('You clicked a breadcrumb.');
  // }

  const handleCardClickItem = (data) => {
    navigate('/app/course-content', { state: data.id });
  };

  const handleClick = (rowData) => {
    navigate('/app/course-content', { state: rowData });
  };

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        Dashboard
      </Typography>
      <Typography variant="body2" color="textPrimary">
        Course
      </Typography>
    </Breadcrumbs>
  );

  useEffect(() => {
    getCourserList(page, searchText, rowsPerPage);
  }, []);

  const tableColumns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'thumbImage',
      label: 'ThumbImage',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Avatar className={classes.avtar} sx={{ width: 50, height: 50 }} variant="square">
              <img src={value} alt="courses" />
            </Avatar>
          );
        },
        customHeadLabelRender: (columnMeta) => {
          return null;
        },
      },
    },
    {
      name: 'author',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{value.name}</Typography>;
        },
      },
    },
    {
      name: 'title',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Typography sx={{ fontSize: '0.875rem' }}>{value}</Typography>
              <Typography className={classes.authorName}>{tableMeta.rowData[2].name}</Typography>
            </>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return <Typography>{value.description}</Typography>;
        // },
        customBodyRender: (value, tableMeta, updateValue) => {
          let colors = '';
          // const Capitalize = value.toLowerCase();
          if (value.code === 'LIVE') {
            colors = 'green';
          } else if (value.code === 'INACTIVE') {
            colors = '#FE7000';
          } else {
            colors = 'gray';
          }
          return (
            <>
              <Typography
                sx={{
                  fontWeight: '400',
                  lineHeight: 'unset',
                  backgroundColor: colors,
                  textAlign: 'center',
                  borderRadius: '4px',
                  padding: '2px 8px',
                  fontSize: '0.9rem',
                  color: '#fff',
                }}
              >
                {value.code === 'LIVE' ? 'Published' : value.description}
              </Typography>
            </>
          );
        },
      },
    },
    {
      name: 'isTrial',
      label: 'Trial',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value === true ? (
                <CheckCircleRoundedIcon className={classes.checkIcon} />
              ) : (
                <CancelRoundedIcon className={classes.cancelIcon} />
              )}
            </>
          );
        },
      },
    },

    {
      name: 'caseStudyStatus',
      label: 'Case study',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value === 'ACTIVE' ? (
                <CheckCircleRoundedIcon className={classes.checkIcon} />
              ) : (
                <CancelRoundedIcon className={classes.cancelIcon} />
              )}
            </>
          );
        },
      },
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography sx={{ fontSize: '0.875rem' }}>{value.description}</Typography>;
        },
      },
    },
    {
      name: 'create_datetime',
      label: 'Created',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography sx={{ fontSize: '0.875rem' }}>{moment(value).format('lll')}</Typography>;
        },
      },
    },
    {
      name: 'lastUpdatedDate',
      label: 'Updated',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography sx={{ fontSize: '0.875rem' }}>{moment(value).format('lll')}</Typography>;
        },
      },
    },
  ];

  return (
    <div>
      <Page title="Course-list">
        <PageHeader pageTitle="Courses" breadcrumbs={<Breadcrumb />} />
        <Container maxWidth={false} sx={{ padding: '0 !important' }}>
          <Grid container spacing={2}>
            <Grid align="end" item xs={12}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <TextField
                  type="search"
                  label="Search Courses"
                  id='searchCourseId'
                  onChange={(e) => {
                    setSearchText(e.target.value !== null ? e.target.value : '');
                    getCourserList(
                      e.target.value === '' ? page : search,
                      e.target.value !== null ? e.target.value : '',
                      rowsPerPage
                    );
                  }}
                />
                <IconButton>
                  <FilterAltIcon id='filteralticonid'fontSize="medium" style={{ color: '#BCBCBC' }} />
                </IconButton>
                <IconButton>
                  <GridOnIcon
                    style={{ color: viewType === 'grid' ? '#00B673' : '#BCBCBC' }}
                    id='gridOnIconId'
                    onClick={() => handleGridClick()}
                    fontSize="medium"
                  />
                </IconButton>
                <IconButton>
                  <ViewListIcon
                    style={{ color: viewType === 'table' ? '#00B673' : '#BCBCBC' }}
                    id='viewListIconId'
                    onClick={() => handleTableClick()}
                    fontSize="medium"
                  />
                </IconButton>
                <Button
                  style={{ marginLeft: '5px' }}
                  id='createCourseButtonId'
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/app/course-type')}
                >
                  + Create course
                </Button>
              </div>
            </Grid>

            {/* {loading ? (
              <>
                {[1, 2, 3, 4, 5, 6].map((res) => (
                  <Grid item xs={12} sm={4} md={4}>
                    <CardSkeleton />
                  </Grid>
                ))}
              </>
            ) : ( */}
            <>
              {viewType === 'grid' ? (
                <>
                  {courseList?.map((item) => {
                    return (
                      <>
                        <Grid item xs="12" sm="6" md="4" lg={4} xl="3">
                          <div className={classes.cardHover}>
                            <Cards
                              handleCardClick={() => handleCardClickItem(item)}
                              image={item.thumbImage}
                              title={item.title}
                              // description={item.description ? item.description : ''}
                              category={item.category}
                              logo={item.category?.categoryImgUrl}
                              author={item.author}
                            />
                          </div>
                        </Grid>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <Grid item xs="12">
                    <ThemeProvider theme={customTheme}>
                      <MUIDataTable
                        style={{
                          '&:hover': {
                            cursor: 'pointer ! important',
                          },
                        }}
                        data={courseList}
                        columns={tableColumns}
                        options={{
                          pagination: false,
                          // responsive: 'scroll',
                          selectableRows: 'none',
                          filter: false,
                          search: false,
                          download: false,
                          print: false,
                          viewColumns: false,
                          textLabels: {
                            body: {
                              noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
                            },
                          },
                          onRowClick: (tableMeta) => {
                            handleClick(tableMeta[0]);
                          },
                        }}
                      />
                    </ThemeProvider>
                  </Grid>
                </>
              )}
              {loading ? (
                <>
                  <Grid item xs="12" sm="12" md="12" lg="12" xl="12" />
                </>
              ) : (
                <>
                  <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                    <div className={classes.pagination}>
                      <TablePagination
                        component="div"
                        count={maxCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </Grid>
                </>
              )}
              {/* {courseList.length === 0 && (
                <Typography marginTop={4} variant="h6" textAlign="center" sx={{ width: '100%' }}>
                  No Data
                </Typography>
              )} */}
            </>
            {/* )} */}
            {/* <Courselist /> */}
          </Grid>
        </Container>
      </Page>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  cardHover: {
    transition: 'all .5s ease-out',
    '&:hover': {
      boxShadow: '0 12px 20px 0 rgb(32 40 45 / 8%)',
      transition: 'transform .5s',
      transform: 'translateY(-5px)',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  avtar: {
    backgroundColor: '#fff',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    borderRadius: '2px',
  },
  authorName: {
    color: '#545151d6',
    fontSize: '0.858rem',
  },
  cancelIcon: {
    color: 'grey',
  },
  checkIcon: {
    color: 'rgb(0, 182, 115)',
  },
}));

const customTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        sortActive: {
          fontFamily: 'Inter,SemiBold',
        },
        data: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
});
