import React, { useState, useEffect } from 'react';
import { Typography, Button, Link, FormControlLabel, IconButton, Breadcrumbs } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import adminServices from '../../../services/adminServices';
import BasicModal from '../../../components/modal/BasicModel';
import CreateAdminForm from './CreateAdminForm';
import DeleteAlert from '../../../components/modal/DeleteModal';
import SnackBar from '../../../components/snackbar/snackbar';
import Modal from '../../../components/modal/Index';
import AddClientForm from './AddClientForm';
import PageHeader from '../../../components/PageHeader';
import Page from '../../../components/Page';

export default function Client(props) {
  const classes = useStyles();
  const [clientList, setClientList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [adminModalOpen, setAdminModalOpen] = useState(false);

  const [deleteAlert, setDeleteAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteClientId, setDeleteClientId] = useState(null);
  const [clientID, setClientID] = useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [mode, setMode] = React.useState('');
  const [clientId, setClientId] = React.useState(null);
  const [trigerData, setTrigerData] = React.useState(false);

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        Dashboard
      </Typography>
      <Typography variant="body2" color="textPrimary">
        Client users
      </Typography>
    </Breadcrumbs>
  );

  // const apiTrigerdData = (value) => {
  //   setTrigerData(!value);
  // };

  const addClientButton = () => {
    handleModalOpen(true);
    setMode('create');
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleEditButton = (id) => {
    setMode('edit');
    setClientId(id);
    setModalOpen(true);
  };

  const handleClose = () => {
    setAdminModalOpen(false);
  };
  const handleModal = (client) => {
    setAdminModalOpen(true);
    setClientID(client);
  };
  const snakbarHandle = (message) => {
    setOpenSnackbar(true);
    setSnackbarTitle(message);
  };

  const getClientLists = async () => {
    try {
      const response = await adminServices.getClientList();
      if (response.ok) {
        setClientList(response.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDeleteModal = (ClientId) => {
    setDeleteAlert(true);
    setDeleteClientId(ClientId);
  };

  const handleDelete = async () => {
    try {
      const data = await adminServices.deleteClientDetailsByID(deleteClientId);
      if (data.ok) {
        setDeleteAlert(false);
        getClientLists();
        setOpenSnackbar(true);
        setSnackbarTitle(data.data.message);
      } else {
        setDeleteAlert(false);
        setOpenSnackbar(true);
        setSnackbarTitle(data.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitch = async (data, id, value) => {
    setClientList(
      clientList.map((item) => {
        if (item.id === data[0]) {
          item.status = data[4] === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
          return item;
        }
        return item;
      })
    );
    try {
      const result = await adminServices.handleClientStatus(data[0], {
        status: data[4] === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      });
      if (result.ok) {
        getClientLists();
        setOpenSnackbar(true);
        setSnackbarTitle(result.data.message);
      } else {
        setOpenSnackbar(true);
        setSnackbarTitle(result.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setLoading(true);
    getClientLists();
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'Client ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'organizationName',
      label: 'Name',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value?.length >= 22 ? (
                <Tooltip title={value} classes={{ arrow: classes.arrowName, tooltip: classes.tooltipName }}>
                  <Typography className={classes.name}>
                    <Link component={RouterLink} to="/app/client-details" state={{ from: tableMeta.rowData[0] }}>
                      {value}
                    </Link>
                  </Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.name}>
                  <Link component={RouterLink} to="/app/client-details" state={{ from: tableMeta.rowData[0] }}>
                    {value}
                  </Link>
                </Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: 'contactEmail',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value.length >= 22 ? (
                <Tooltip title={value} classes={{ arrow: classes.arrowName, tooltip: classes.tooltipName }}>
                  <Typography className={classes.emailText}>{value}</Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.emailText}>{value}</Typography>
              )}
            </>
          );
        },
      },
    },

    {
      name: 'clientAdmin',
      label: 'Admin',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value !== null ? (
                <Typography>{value.fullName}</Typography>
              ) : (
                <>
                  <Button
                    style={{ minWidth: '126px', borderColor: '#707070', color: '#637381' }}
                    onClick={() => {
                      handleModal(tableMeta.rowData[0]);
                    }}
                    variant="outlined"
                  >
                    Create Admin
                  </Button>
                </>
              )}
            </>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <FormControlLabel
                checked={value === 'ACTIVE'}
                value={value}
                onClick={() => {
                  handleSwitch(tableMeta.rowData);
                }}
                control={<Switch color="secondary" {...label} />}
              />
            </>
          );
        },
      },
    },
    {
      name: 'subscriptionDetails',
      label: 'Action',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const subscriptions =
            value &&
            value.map((item) => {
              return (
                <>
                  <Typography gutterBottom style={{ fontSize: '14px' }}>
                    {`(${item.noOfLicenseAllocated}) ${item.subscriptionName}`}
                  </Typography>
                </>
              );
            });

          return (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#747474',
                    borderRadius: '4px',
                    padding: '3px 6px ',
                    marginRight: '7px',
                    minWidth: '123px',
                  }}
                >
                  {value && value !== null ? (
                    <ThemeProvider theme={customeTheme}>
                      <Tooltip title={subscriptions} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}>
                        <Typography style={{ color: 'white' }}>{`(${value?.length}) subscriptions`}</Typography>
                      </Tooltip>
                    </ThemeProvider>
                  ) : (
                    <Typography style={{ color: 'white' }}>{`(0) subscription`}</Typography>
                  )}
                </div>

                <IconButton
                  onClick={() => {
                    handleEditButton(tableMeta.rowData[0]);
                  }}
                >
                  <EditIcon fontSize="small" style={{ color: '#BCBCBC' }} />
                </IconButton>

                <IconButton
                  onClick={() => {
                    handleOpenDeleteModal(tableMeta.rowData[0]);
                  }}
                >
                  <DeleteIcon fontSize="small" style={{ color: '#BCBCBC' }} />
                </IconButton>
              </div>
            </>
          );
        },
      },
    },
  ];
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  return (
    <Page title={'users'}>
      <PageHeader pageTitle="Clients" breadcrumbs={<Breadcrumb />} />
      <div style={{ marginTop: '1rem' }}>
        <MUIDataTable
          data={clientList}
          columns={columns}
          options={{
            customToolbar: () => (
              <Button
                sx={{ marginLeft: '1rem' }}
                variant="contained"
                onClick={() => {
                  addClientButton();
                }}
              >
                {' '}
                <AddIcon fontSize="small" />
                Add Client
              </Button>
            ),
            responsive: 'scroll',
            rowsPerPage: 25,
            rowsPerPageOptions: [25, 50, 75, 100],
            selectableRows: false,
            filter: false,
            download: false,
            print: false,
            textLabels: {
              body: {
                noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
              },
            },
          }}
        />
        <BasicModal
          openModel={adminModalOpen}
          title={'Add Client Admin'}
          children={
            <CreateAdminForm
              snackBarControl={snakbarHandle}
              getClientList={getClientLists}
              handleClose={handleClose}
              clientID={clientID}
            />
          }
          closeModel={() => setAdminModalOpen(false)}
        />
        <Modal
          title={mode === 'create' ? 'Add Clients' : 'Edit Clients'}
          open={modalOpen}
          toggle={handleModalClose}
          children={
            <AddClientForm
              mode={mode}
              clientId={clientId}
              getClientList={getClientLists}
              toggle={handleModalClose}
              snackBarControl={snakbarHandle}
            />
          }
        />
        {/* <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} /> */}

        <DeleteAlert
          open={deleteAlert}
          title="Are you sure you want delete this Client"
          confirm={handleDelete}
          close={() => setDeleteAlert(false)}
        />
        <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
      </div>
    </Page>
  );
}
const useStyles = makeStyles((theme) => ({
  name: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '155px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
    },
  },
  emailText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '180px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
    },
  },
  arrow: {
    color: '#FE7000',
  },
  tooltip: {
    backgroundColor: '#FE7000',
    color: '#FFFFFF',
  },
  arrowName: {
    [theme.breakpoints.up('md')]: {
      color: 'unset',
      display: 'none',
    },
  },
  tooltipName: {
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid gray',
    },
  },
}));
const customeTheme = createTheme({
  components: {
    // MuiTooltip: {
    //   styleOverrides: {
    //     tooltip: {
    //       color: '#FFFFFF',
    //       backgroundColor: '#FE7000',
    //     },
    //   },
    // },
  },
});
