import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Typography, Button, Link, FormControlLabel, IconButton, Breadcrumbs } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MUIDataTable from 'mui-datatables';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';

import adminServices from '../../../services/adminServices';
import BasicModal from '../../../components/modal/BasicModel';
import CreateAdminForm from './CreateAdminForm';
import DeleteAlert from '../../../components/modal/DeleteModal';
import SnackBar from '../../../components/snackbar/snackbar';
import PageHeader from '../../../components/PageHeader';
import Page from '../../../components/Page';

export default function Individual(props) {
  const classes = useStyles();
  const [clientList, setClientList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteClientId, setDeleteClientId] = useState(null);
  const [clientID, setClientID] = useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchKey, setSearchKey] = useState('');
  const [sortKey, setSortKey] = useState('createDateTime');
  const [sortOrder, setSortOrder] = useState('desc');
  const [mode, setMode] = React.useState('');
  const [clientId, setClientId] = React.useState(null);
  const [trigerData, setTrigerData] = React.useState(false);

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        Dashboard
      </Typography>
      <Typography variant="body2" color="textPrimary">
        Individual users
      </Typography>
    </Breadcrumbs>
  );

  // const apiTrigerdData = (value) => {
  //   setTrigerData(!value);
  // };

  const getClientLists = async (pageNum, rows, sortOrd, sortCol, searchWord) => {
    setLoading(true);
    try {
      const response = await adminServices.getIndividualList(pageNum, rows, sortOrd, sortCol, searchWord);
      if (response.ok) {
        setClientList(response.data?.records);
        setPageCount(response?.data?.metadata?.totalRecordCount);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getClientLists(page, rowsPerPage, sortOrder, sortKey, searchKey);
  }, []);

  const columns = [
    {
      name: 'firstName',
      label: 'Name',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = tableMeta.rowData;
          const combinedValue = rowData[0].concat(' ', rowData[1]);
          return <Typography className={classes.name}>{combinedValue} </Typography>;
        },
      },
    },

    {
      name: 'lastName',
      label: 'Last name',
      options: {
        filter: true,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography className={classes.name}>{value}</Typography>;
        },
      },
    },

    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{value}</Typography>;
        },
      },
    },

    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{value?.length > 6 ? `+${value}` : ''}</Typography>;
        },
      },
    },
    {
      name: 'createDateTime',
      label: 'Created On',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{moment(value).format('lll')}</Typography>;
        },
      },
    },

    // {
    //   name: 'isStudent',
    //   label: 'Student',
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <Typography>{value ? 'YES' : 'NO'}</Typography>;
    //     },
    //   },
    // },
  ];
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  return (
    <Page title={'users'}>
      <PageHeader pageTitle="Individual users" breadcrumbs={<Breadcrumb />} />
      <div style={{ marginTop: '1rem' }}>
        <MUIDataTable
          data={clientList}
          columns={columns}
          options={{
            pagination: true,
            filter: false,
            responsive: 'scroll',
            print: false,
            jumpToPage: true,
            download: true,
            page,
            search: true,
            count: pageCount,
            serverSide: true,
            rowsPerPage,
            rowsPerPageOptions: [10, 25, 50, 75, 100],
            selectableRows: false,

            textLabels: {
              body: {
                noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
              },
            },
            onTableChange: (action, tableState) => {
              if (action === 'changeRowsPerPage') {
                setRowsPerPage(tableState.rowsPerPage);
                setClientList([]);
                setPage(tableState.page);
                setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                getClientLists(
                  tableState.page + 1,
                  tableState.rowsPerPage,
                  tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                  tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                  tableState.searchText !== null ? tableState.searchText : ''
                );
              } else if (action === 'changePage') {
                setRowsPerPage(tableState.rowsPerPage);
                setClientList([]);
                setPage(tableState.page);
                setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                getClientLists(
                  tableState.page + 1,
                  tableState.rowsPerPage,
                  tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                  tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                  tableState.searchText !== null ? tableState.searchText : ''
                );
              } else if (action === 'search') {
                setRowsPerPage(tableState.rowsPerPage);
                setClientList([]);
                setPage(tableState.page);
                setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                getClientLists(
                  tableState.page + 1,
                  tableState.rowsPerPage,
                  tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                  tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                  tableState.searchText !== null ? tableState.searchText : ''
                );
              } else if (action === 'sort') {
                setRowsPerPage(tableState.rowsPerPage);
                setClientList([]);
                setPage(tableState.page);
                setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                getClientLists(
                  tableState.page + 1,
                  tableState.rowsPerPage,
                  tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                  tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                  tableState.searchText !== null ? tableState.searchText : ''
                );
              }
            },
          }}
        />
      </div>
    </Page>
  );
}
const useStyles = makeStyles((theme) => ({
  name: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '155px',
    '&:hover': {
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'break-all',
    },
  },
  emailText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '280px',
    '&:hover': {
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'break-all',
    },
  },
  arrow: {
    color: '#FE7000',
  },
  tooltip: {
    backgroundColor: '#FE7000',
    color: '#FFFFFF',
  },
}));
const customeTheme = createTheme({
  components: {
    // MuiTooltip: {
    //   styleOverrides: {
    //     tooltip: {
    //       color: '#FFFFFF',
    //     },
    //   },
    // },
  },
});
