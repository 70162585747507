import React, { useEffect, useState } from 'react';

import { Typography, Breadcrumbs, LinearProgress, Chip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';
import reports from '../../../services/report/reports';

const UserCourseProgress = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const response = await reports.getUserCourseProgress();
      if (response.ok) {
        setLoading(false);
        setData(response.data);
      } else {
        setLoading(false);
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tableColumns = [
    {
      name: 'courseId',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'email',
      label: 'Customer',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'title',
      label: 'Course',
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'overallProgress',
      label: 'Course progress',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <LinearProgress variant="determinate" value={value} style={{ height: 20, minWidth: '8rem' }} />
              <div
                style={{
                  textAlign: 'center',
                  position: 'absolute',
                }}
              >
                {value}%
              </div>
            </div>
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'subscribedOn',
      label: 'Subscribed on',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? <Typography className={classes.dateWidth}>{moment(value).format('lll')}</Typography> : '';
        },
      },
    },
    {
      name: 'startDate',
      label: 'Simulation started',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? <Typography className={classes.dateWidth}>{moment(value).format('lll')}</Typography> : '';
        },
      },
    },
    {
      name: 'lastAttendedDate',
      label: 'Last simulation date',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? <Typography className={classes.dateWidth}>{moment(value).format('lll')}</Typography> : '';
        },
      },
    },
    {
      name: 'caseStudyAttended',
      label: 'Case study attempted',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <>
              {value === 'true' ? (
                <CheckCircleRoundedIcon className={classes.checkIcon} />
              ) : (
                <CancelRoundedIcon className={classes.cancelIcon} />
              )}
            </>
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'caseStudyStarDate',
      label: 'Case study started on',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? <Typography className={classes.dateWidth}>{moment(value).format('lll')}</Typography> : '';
        },
      },
    },
    {
      name: 'casestudyResult',
      label: 'Case study result',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? (
            <Chip
              size="small"
              label={value}
              sx={{
                backgroundColor: value === 'pass' ? 'green' : 'red',
                color: 'white',
              }}
            />
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'sendCertificate',
      label: 'Certificate sent',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let icon = null;
          if (value === true) {
            icon = <CheckCircleRoundedIcon className={classes.checkIcon} />;
          } else if (value === false) {
            icon = <CancelRoundedIcon className={classes.cancelIcon} />;
          }
          return <>{icon}</>;
        },
      },
    },
    {
      name: 'subscriptionStatus',
      label: 'Subscription Status',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              variant="outlined"
              size="small"
              label={value}
              sx={{
                backgroundColor: value === 'ACTIVE' ? 'green' : 'red',
                color: 'white',
                fontSize: '0.65rem',
              }}
            />
          );
        },
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{value ? `+${value}` : ''}</Typography>;
        },
      },
    },
  ];

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        Dashboard
      </Typography>
      <Typography variant="body2" color="textPrimary">
        User course progress
      </Typography>
    </Breadcrumbs>
  );

  return (
    <Page title="User-course-progress">
      <PageHeader pageTitle="User Course progress" breadcrumbs={<Breadcrumb />} />
      <MUIDataTable
        data={data}
        columns={tableColumns}
        // title="Subscription Info"
        options={{
          rowsPerPage: 25,
          responsive: 'scroll',
          rowsPerPageOptions: [25, 50, 75, 100],
          selectableRows: 'none',
          filter: true,
          download: true,
          print: false,
          viewColumns: false,
          textLabels: {
            body: {
              noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
            },
          },
        }}
      />
    </Page>
  );
};

export default UserCourseProgress;

const useStyles = makeStyles((theme) => ({
  dateWidth: {
    width: '110px',
    fontSize: '0.95rem',
  },
  customerLink: {
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: '#000',
    },
  },
  cancelIcon: {
    color: 'grey',
  },
  checkIcon: {
    color: 'rgb(0, 182, 115)',
  },
}));
