import React, { useState, useEffect } from 'react';
import { Breadcrumbs, Box, Button, Fade, Grid, IconButton, Modal, Paper, Typography, Tooltip ,CircularProgress} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { useSelector } from 'react-redux';
import 'react-html5video/dist/styles.css';
import { useNavigate, useLocation, NavLink, Link as RouterLink } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MoveIcon from '@mui/icons-material/TrendingFlatSharp';
import Cookies from 'js-cookie';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import swal from 'sweetalert';
import CloseIcon from '@mui/icons-material/Close';
import TrialModal from '../../../components/modal/TrialModal';
import AddSubModuleForm from '../../../components/Forms/CreateModule';
import DeleteAlert from '../../../components/modal/DeleteModal';
import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';
import adminServices from '../../../services/adminServices';
import CourseContentSkeleton from '../../../components/Skeleton/CourseContentSkeleton';
import CreateCourse from './CreateCourseForm';
import SnackBar from '../../../components/snackbar/snackbar';
import CaseStudies from './CaseStudies';
import clientAdminServices from '../../../services/clientAdmin/course/clientAdminServices';
import ImportSubModuleButton from './ImportSubModule';
import ScreensValidation from './ScreensValidation';
import MoveSubModule from './MoveSubModule';
import '../../users/course/index.css';
import Iconify from '../../../components/Iconify';
import AddRererenceModel from './AddReferenceModel';

const CourseContent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const isGoBack = true;
  const courseId = location.state;

  const [deleteAlert, setDeleteAlert] = useState(false);
  const [openSubModule, setOpenSubModule] = useState(false);
  const [openSubModuleMove, setOpenSubModuleMove] = useState(false);
  const [moveFromModule, setMoveFromModule] = useState('')
  const [expanded, setExpanded] = React.useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modelTitle, setModelTitle] = useState('');
  const [formType, setFormType] = useState('simulation');
  const [formCategory, setFormCategory] = useState('subModule');
  const [modalBtnTxt, setModalBtnTxt] = useState('Create');
  const [moduleId, setModuleId] = useState('');
  const [subModuleId, setSubModuleId] = useState('');
  const [courseData, setCourseData] = useState(null);
  const [textFieldName, setTextFieldName] = useState('');
  const [courseDetails, setCourseDetails] = useState({});
  const [courseContent, setCourseContent] = useState([]);
  const [deleteType, setDeleteType] = useState(null);
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const [formValue, setFormValue] = useState(null);
  const [deleteModalTitle, setDeleteModalTitle] = useState('');
  const [courseStatus, setCourseStatus] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [buttonIndex, setButtonIndex] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [isWantMoreText, setIsWantMoreText] = useState(true);
  const [label, setLabel] = React.useState('');
  const [openTrialModal, setOpenTrialModal] = React.useState(false);
  const [trialModalLoading, setTrialModalLoading] = React.useState(false);
  const [progress, setProgress] = useState(0);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [courses, setCourses] = useState([]);
  const [importSuccess, setImportSuccess] = useState(false); 
  const [moduleIdForReport, setModuleIdForReport] = useState(null);
  const [moveSubmoduleId, setMoveSubModuleId] = useState('')
  const [openAddReference,setAddOpenReference]=useState(false);
  const [actionType, setActionType] = useState("");
  const [updateBtnLoading, setUpdateBtnLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [videoTitle, setVideoTitle] = useState({
    title: '',
    video: null,
  });
  const [referenceValue,setReferenceValue]=useState(null)
  const [subModuleName,setSubModuleName]=useState('');

  const handleImportSuccess = () => {
    setImportSuccess(true);
    setOpenSnackbar(true);
    setSnackbarTitle('Import operation completed,loading submodules may take some time!!');
    getCourseModules()
  };
  
  const user = useSelector((state) => state.userInfo);
  const [isAuthor,setisAuthor] = React.useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: expanded === expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  useEffect(() => {
    setSkeletonLoading(true)
    getCourseContent();
    getCourseModules();
    getCourseStatusData();
  }, []);

  const handleExpandClick = (i) => {
    setExpanded(expanded === i ? -1 : i);
  };

  const handleCloseTrialModal = () => {
    setOpenTrialModal(false);
  };

  const handleCloseMoveSubModule = () =>{   
    setOpenSubModuleMove(false);    
  }
  const handleCloseAddReference=()=>{
    setAddOpenReference(false);
  }
  const submitReference = async (values) => {
    setBtnLoading(true);
    try {
        let referenceresponse;
        if (formType === "createRef") {
            const formData = new FormData();
            formData.append('refferenceText', values?.description);
            if(values?.referenceFile){
            formData.append('referenceFile', values?.referenceFile);
            formData.append('contentType', values?.referenceFile?.type);
          }
            referenceresponse = await adminServices.createReference(moduleId, subModuleName, formData);
        } else if (formType === "editRef") {
          const editFormData = new FormData();
                editFormData.append('referenceText', values?.description);
                editFormData.append('referenceFile', values?.referenceFile);
                editFormData.append('contentType', values?.referenceFile?.type);
                if(values?.referenceid!==null){
                editFormData.append('referenceid', values?.referenceid);
                }
            if (actionType === "update") {
              setUpdateBtnLoading(true);
                referenceresponse = await adminServices.editCourseSubModuleRef(moduleId, subModuleName, editFormData);
            } else if (actionType === "delete") {
              const alertRes = await swal({
                text: 'Are you sure you want to delete this Reference?',
                buttons: {
                  confirm: {
                    text: 'Okay',
                    value: true,
                    visible: true,
                    className: '',
                    closeModal: true,
                  },
                  cancel: {
                    text: 'Cancel',
                    value: null,
                    visible: true,
                    className: '',
                    closeModal: true,
                  },
                },
                closeOnClickOutside: false,
              });
              if(alertRes){
                setDeleteBtnLoading(true);
                referenceresponse=await adminServices.deletreferenceDetails(values?.subModuleId,values.referenceid)
              }
              
            }
        }
        if (referenceresponse?.ok) {
            setAddOpenReference(false);
            setOpenSnackbar(true);
            setSnackbarTitle(referenceresponse.data.message);
            getCourseModules();
        } else {
            console.error('Error response:', referenceresponse);
            // Handle non-200 responses here if needed
        }
    } catch (error) {
        console.error(error);
    } finally {
        // Set loading to false when the submission is completed (whether successful or not)
        setBtnLoading(false);
        setUpdateBtnLoading(false);
        setDeleteBtnLoading(false);
    }
};

  // function will call when creating a module or submodule and updating a module or submodule
  
  const submitModuleForm = async (values) => {
    setProgress(0);
    setBtnLoading(true);
    // creating new module
    if (formType === 'module') {
      const data = {
        courseModuleName: values.courseTitle,
        isTrial: values.isTrial ? 'true' : 'false',
      };
      const modData = { ...data };
      if (values.videoFile) {
        modData.fileName = values.videoFile?.name;
        modData.contentType = values.videoFile?.type;
      }
      const courseResponse = await adminServices.createCourseModule(courseId, modData);
      if (courseResponse.ok) {
        // pre-sign url integration
        if (values.videoFile) {
          await axios
            .put(courseResponse.data?.preSignedUrl, values.videoFile, {
              headers: {
                'Content-Type': values.videoFile?.type,
                // 'x-amz-acl': 'public-read',
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                console.log(`Upload Progress: ${percentCompleted}%`);
                setProgress(percentCompleted);
              },
            })
            .then((response) => {
              if (response.status === 200) {
                setOpenSubModule(false);
                getCourseModules();
              }
              setBtnLoading(false);
            });
          return;
        }
        setOpenSubModule(false);
        getCourseModules();
      } else {
        setOpenSnackbar(true);
        setSnackbarTitle('Please try again');
      }
      setProgress(0);
      // creating new submodule
    } else if (formType === 'subModule') {
      const subData = {
        courseSubmoduleName: values.courseTitle,
        isTrial: values.isTrial ? 'true' : 'false',
        
      };
      const subModData = { ...subData };
      if (values.videoFile) {
        subModData.fileName = values.videoFile?.name;
        subModData.contentType = values.videoFile?.type;
      }
      const subModuleResponse = await adminServices.createCourseSubModule(moduleId, subModData);
      if (subModuleResponse.ok) {
        // pre-sign url integration
        if (values.videoFile) {
          await axios
            .put(subModuleResponse.data?.preSignedUrl, values.videoFile, {
              headers: {
                'Content-Type': values.videoFile?.type,
                // 'x-amz-acl': 'public-read',
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                console.log(`Upload Progress: ${percentCompleted}%`);
                setProgress(percentCompleted);
              },
            })
            .then((response) => {
              if (response.status === 200) {
                console.log('response', response);
                getCourseModules();
                setOpenSubModule(false);
              }
              setBtnLoading(false);
            });
          return;
        }
        getCourseModules();
        setOpenSubModule(false);
      }
      setProgress(0);
    } else if (formType === 'EditSubmodule') {
      const subData = {
        courseSubmoduleName: values.courseTitle,
        isTrial: values.isTrial ? 'true' : 'false',
      };
      setFormCategory()
      const subModData = { ...subData };
      if (values.videoFile) {
        subModData.fileName = values.videoFile?.name;
        subModData.contentType = values.videoFile?.type;
      }
      const editModuleResponse = await adminServices.editCourseSubModuleName(moduleId, subModuleId, subModData);
      if (editModuleResponse.ok) {
        // pre-sign url integration
        if (values.videoFile) {
          await axios
            .put(editModuleResponse.data?.preSignedUrl, values.videoFile, {
              headers: {
                'Content-Type': values.videoFile?.type,
               //  'x-amz-acl': 'public-read',
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                console.log(`Upload Progress: ${percentCompleted}%`);
                setProgress(percentCompleted);
              },
            })
            .then((response) => {
              if (response.status === 200) {
                setOpenSubModule(false);
                getCourseModules();
              }
            });
          setBtnLoading(false);
          return;
        }
        setOpenSubModule(false);
        getCourseModules();
      }
      setProgress(0);
    } else {
      // Update module details
      const modData = {
        courseModuleName: values.courseTitle,
        isTrial: values.isTrial ? 'true' : 'false',
      };
      const editData = { ...modData };
      if (values.videoFile) {
        editData.fileName = values.videoFile?.name;
        editData.contentType = values.videoFile?.type;
      }
      const editResponse = await adminServices.editCourseModuleName(courseId, moduleId, editData);
      if (editResponse.ok) {
        // pre-sign url integration
        if (values.videoFile) {
          await axios
            .put(editResponse.data?.video, values.videoFile, {
              headers: {
                'Content-Type': values.videoFile?.type,
                // 'x-amz-acl': 'public-read',
              },
              onUploadProgress: (progressEvent) => {
                setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
              },
            })
            .then((response) => {
              if (response.status === 200) {
                console.log('response', response);
                setOpenSubModule(false);
                getCourseModules();
              }
            });
          setBtnLoading(false);
          return;
        }
        setOpenSubModule(false);
        getCourseModules();
      }
      setProgress(0);
    }

    setBtnLoading(false);
  };

  const getCourseContent = async () => {
    const response = await adminServices.getCourseContent(courseId);
    if (response.ok) {
      
      let data = response.data
      
      if(data.authorList===null || data.authorList===undefined){
        data = {
            ...response.data,
            authorList:[]
        }
      }
      console.log('getCourseContent data', data);
      setCourseDetails(data);
    }
  };

  const getCourseModules = async () => {
    const result = await adminServices.getCourseModule(courseId);
    if (result.ok) {
      const data = result.data.sort((a, b) => a.positionIndex - b.positionIndex);
      setCourseContent(data);
      setSkeletonLoading(false);
    }
  };

  const handleDeleteSubModule = async () => {
    if (deleteType === 'SUBMODULE') {
      const deleteModuleRes = await adminServices.deleteSubModules(moduleId, subModuleId);
      if (deleteModuleRes.ok) {
        getCourseModules();
        setDeleteAlert(false);
      }
    } else if (deleteType === 'COURSE') {
      handleDeleteCourse();
    } else {
      const deleteCourseRes = await adminServices.deleteCourseModule(courseId, moduleId);
      if (deleteCourseRes.ok) {
        getCourseModules();
        setDeleteAlert(false);
      }
    }
  };

  const getCourseDetailsById = async () => {
    setOpenModal(true);
    const result7 = await adminServices.getCourseDetailsById(courseId);
    if (result7.ok) {
      let data = result7.data
      
      if(data.authorList===null){
        data = {
            ...result7.data,
            authorList:[]
        }
      }

      setCourseData(data);
      console.log('course data',courseData)
    }
  };

  const handleSubmitMoveSubModule = async (values) =>{
    
    setOpenSubModuleMove(false)
    getCourseModules();
    setOpenSnackbar(true);
    setSnackbarTitle('Sub module moved successfully!');
  }

  const handleSubmitCourseForm = async (values) => {
    setBtnLoading(true);
    const array = [];
    array.push(values.hashtag.map((res) => res.code));
    const formData = new FormData();
    console.log('values on submit',values)
    formData.append('category', values.courseCategory.code);
    formData.append('title', values.courseTitle);
    formData.append('description', values.description);
    formData.append('hashTag', JSON.stringify(array[0]));
    formData.append('isTrial', JSON.stringify(values.isTrial));
    formData.append("authors", values.authors);

    if (values.courseCategory.code === 'SOFTWARE_DEVELOPMENT') {
      formData.append('subCategory', values?.subCategory?.code);
    }

    if (values.thumbImage) {
      formData.append('thumbImage', values.thumbImage);
    }
    if (values.coverageImage) {
      formData.append('coverageImage', values.coverageImage);
    }
    if (values.videoFile) {
      formData.append('fileName', values.videoFile?.name);
      formData.append('contentType', values.videoFile?.type);
    }

    try {
      const response = await adminServices.editCourseDetails(courseId, formData);
      if (response.ok) {
        if (values.videoFile) {
          await axios
            .put(response.data?.preSignedUrl, values.videoFile, {
              headers: {
                'Content-Type': values.videoFile?.type,
                // 'x-amz-acl': 'public-read',
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
              },
            })
            .then((response) => {
              if (response.status === 200) {
                setBtnLoading(false);
                setOpenModal(false);
                getCourseContent();
              } else {
                console.log(response);
                // props.snackBarControl('Please try again');
              }
            });
          setProgress(0);
          return;
        }
        getCourseContent();
      }
    } catch (error) {
      console.log(error);
      setBtnLoading(false);
    }

    setBtnLoading(false);
    setOpenModal(false);
  };

  // drag the item and position changing when dropped item
  const handleDrop = async (droppedItem) => {
    const courseModuleId = droppedItem.destination.droppableId;
    if (!droppedItem.destination) return;
    if (droppedItem.type === 'MODULE') {
      const updatedList = [...courseContent];
      // Remove dragged item
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      // Add dropped item
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      // Update State
      setCourseContent(updatedList);
      const positionArray = [];
      updatedList.map((res, index) => {
        const item = {
          id: res.id,
          positionIndex: index + 1,
        };
        positionArray.push(item);
        return item;
      });
      try {
        const positionRes = await adminServices.updateModulePositions(courseId, positionArray);
        if (positionRes.ok) {
          console.log(positionRes);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      let resultArray = [];
      const updatedSubList = [...courseContent];
      updatedSubList.map((item, i) => {
        if (item.id === courseModuleId) {
          // Remove dragged item
          const [reorderedSubItem] = updatedSubList[i].subModule.splice(droppedItem.source.index, 1);
          // Add dropped item
          updatedSubList[i].subModule.splice(droppedItem.destination.index, 0, reorderedSubItem);
          // Update State
          resultArray = updatedSubList;
          setCourseContent(updatedSubList);
        }
        return item;
      });

      const positionArray = [];
      resultArray.map((res) => {
        if (res.id === courseModuleId) {
          res.subModule.map((subModule, indx) => {
            const item = {
              id: subModule.id,
              positionIndex: indx + 1,
            };
            positionArray.push(item);
            return item;
          });
        }
        return res;
      });
      try {
        const positionRes = await adminServices.updateSubModulePositions(courseModuleId, positionArray);
        if (positionRes.ok) {
          console.log(positionRes);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteCourse = async () => {
    const deleteRes = await adminServices.deleteCourseById(courseId);
    if (deleteRes.ok) {
      navigate('/app/course');
    }
  };

  const getCourseStatusData = async () => {
    try {
      const res = await adminServices.getCourseStatus('course_status');
      if (res.ok) {
        setCourseStatus(res.data.course_status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCourseStatus = async (data, index) => {
    setButtonIndex(index);
    try {
      const item = await adminServices.updateCourseStatus(courseId, {
        status: data,
        type: courseDetails.type
      });
      if (item.ok) {
        setOpenSnackbar(true);
        setSnackbarTitle(item.data.message);
        getCourseContent();
        setAnchorEl(null);
      } else {
        setOpenSnackbar(true);
        setSnackbarTitle(item.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    setButtonIndex(null);
  };

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        Dashboard
      </Typography>
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/course"
      >
        Course
      </Typography>
      <Typography variant="body2" color="textPrimary">
        Course details
      </Typography>
    </Breadcrumbs>
  );

  // delete the course introduction video
  const handleDeleteCourseVideo = () => {
    adminServices.deleteCourseVideo(courseId).then((response) => {
      if (response.ok) {
        getCourseContent();
        getCourseDetailsById();
      }
    });
  };

  const handleSubmitTrialModal = async (courseDetails) => {
    setTrialModalLoading(true);
    try {
      const res = await clientAdminServices.postTrialDetailsOnCourseId(JSON.stringify(courseDetails));
      if (res.ok) {
        setSnackbarTitle('Trial details modified successfully.');
        setOpenSnackbar(true);
        setTrialModalLoading(false);
        setOpenTrialModal(false);
      } else {
        setSnackbarTitle('Failed to modify Trial details.');
        setOpenSnackbar(true);
        setTrialModalLoading(false);
      }
    } catch (error) {
      setSnackbarTitle('Something went wrong please try agin.');
      setOpenSnackbar(true);
      setTrialModalLoading(false);
      console.log(error);
    }
  };

  let colors = '';
  if (courseDetails?.status?.code === 'LIVE') {
    colors = 'green';
  } else if (courseDetails?.status?.code === 'EXPIRED') {
    colors = 'red';
  } else {
    colors = 'gray';
  }

  const ButtonGroup = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box textAlign="left" mb={3} sx={{ display: 'flex' }}>
          <Typography style={{ color: '#878585e6' }}>Course ID : {courseId}</Typography>
          {courseDetails?.status && (
            <Typography
              sx={{
                width: 'min-content',
                fontWeight: '400',
                lineHeight: 'unset',
                backgroundColor: colors,
                textAlign: 'center',
                borderRadius: '4px',
                padding: '2px 10px',
                fontSize: '0.85rem',
                color: 'white',
                marginLeft: '1rem',
              }}
            >
              {courseDetails?.status?.description}
            </Typography>
          )}
          <Typography style={{ color: '#878585e6',marginLeft: '2rem' }}>Authors : [{(courseDetails.authorList)?.join(',')}]</Typography> 
          <Typography style={{ color: '#878585e6',marginLeft: '2rem' }}>Type: {courseDetails.type}</Typography>
        </Box>
        <Box textAlign="right" mb={3}>
          <Button variant="outlined" id='trialButtonId' sx={{ marginRight: '8px' }} onClick={() => setOpenTrialModal(true)}>
            Trial
          </Button>
          <Button
            variant="contained"
            id='editCourseButtonId'
            sx={{ backgroundColor: '#6D6969', marginRight: '8px' }}
            startIcon={<ModeEditIcon fontSize="small" />}
            onClick={() => getCourseDetailsById()}
          >
            Edit Course
          </Button>
          <IconButton aria-describedby={id} variant="contained" id='moreVertIconId' onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: 175, left: 1300 }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              sx={{ mt: 1 }}
              onClick={() => {
                setDeleteType('COURSE');
                setDeleteAlert(true);
                setDeleteModalTitle('Are you sure you want delete this course?');
                setAnchorEl(null);
              }}
            >
              <DeleteIcon fontSize="small" id='deleteId' style={{ color: '#BCBCBC' }} />
              &nbsp; <Typography>Delete</Typography>
            </MenuItem>

            <div style={{ padding: '12px 12px' }}>
              {courseStatus &&
                courseStatus.map((item, index) => {
                  return (
                    <div key={index}>
                      {courseDetails.status && courseDetails.status.code === item.code ? null : (
                        <LoadingButton
                        key={item.code}
                        id='descriptionId'
                          onClick={() => {
                            handleCourseStatus(item.code, index);
                          }}
                          type="submit"
                          style={{
                            margin: '3px',
                            borderRadius: '50px',
                            minWidth: '140px',
                          }}
                          size="small"
                          variant="outlined"
                          loading={index === buttonIndex}
                        >
                          <Typography>{item.description}</Typography>
                        </LoadingButton>
                      )}
                    </div>
                  );
                })}
            </div>
          </Popover>
        </Box>
      </div>
    );
  };

  const handlePreviewSimulation = (subModuleId, module, resumeClic = false) => {
    navigate(`/app/simulation-preview?id=${subModuleId}&module=${module}`, {
      replace: true,
      state: courseDetails,
    });
  };

  const SubmoduleComponent = ({ module, subModule, moduleNumber }) => {
    return (
      <>
        <Droppable droppableId={module.id} type={`${moduleNumber}`}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {subModule &&
                subModule.map((response, index) => {
                  return (
                    <Draggable key={`${moduleNumber}${index}`} draggableId={`${moduleNumber}${index}`} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <Box
                            className={classes.cardBox}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            {...provided.dragHandleProps}
                          >
                            <Box>
                              <Typography sx={{ fontSize: '0.85rem' }}>
                                Sub Module
                                {index + 1}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                // component={LinkBtn}
                                // to={{
                                //   pathname: '/app/submodules',
                                // }}
                                sx={{ textDecoration: 'none !important' }}
                                color="primary"
                                onClick={() => {
                                  navigate('/app/submodules', { state: courseDetails });
                                  Cookies.set('subModule', JSON.stringify(response));
                                }}
                              >
                                {response.courseSubmoduleName}
                              </Typography>
                            </Box>
                            <div>
                              <Tooltip title="Simulation preview" arrow>
                                <Button
                                  size="small"
                                  id='simulateId'
                                  onClick={() => handlePreviewSimulation(response.id, module.courseModuleName)}
                                >
                                  {/* <Iconify icon="pajamas:live-preview" width={20} height={20} /> */}
                                  simulate
                                </Button>
                              </Tooltip>
                              { // eslint-disable-next-line prefer-template     
                              (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName))&&
                              <Tooltip title="Move submodule to another module" arrow>
                                <IconButton
                                          size="small"
                                          id='moveIconId'
                                          sx={{ marginRight: '10px' }}
                                          onClick={() => {
                                            setOpenSubModuleMove(true);
                                            setMoveFromModule(module.id)
                                            setMoveSubModuleId(response.id)
                                          }}
                                        >
                                          <MoveIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            }
                              {response.video && (
                                <Tooltip title="Includes video" arrow>
                                  <IconButton
                                    size="small"
                                     id='IconButtonForVideoId'
                                     onClick={() => {
                                      setOpenVideoModal(true);
                                      setVideoTitle({
                                        title: response.courseSubmoduleName,
                                        video: response.video,
                                      });
                                    }} 
                                  >
                                    <Iconify icon="pajamas:live-preview" width={20} height={20} />
                                  </IconButton>
                                </Tooltip>
                              )} 
                              {
                                (user.role === 'SUPER_ADMIN' || courseDetails.authorList.includes(`${user.firstName} ${user.lastName}`)) &&
                                  
                                    response.referencetext === null && response?.referencefile === null ? (
                                  <Tooltip title="Add Reference" arrow>
                                    <IconButton
                                      size='small'
                                      id='attachFileIconForCourseContentId'
                                      onClick={() => {
                                        setReferenceValue({
                                          referencefile:null,
                                          referencetext:null,
                                          referenceFileView:null,
                                          referenceFileName:null
                                        })
                                        console.log("onclick of reference", response)
                                        console.log("onclick of reference....>", module)
                                        setModuleId(module.id);
                                        setAddOpenReference(true);
                                        setSubModuleName(response.courseSubmoduleName)
                                        setModalBtnTxt("Create")
                                        setFormType("createRef")
                                        setModelTitle('Add Reference');
                                      }}
                                    >
                                      <AttachFileIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : 
                                <Tooltip title="Edit Reference" arrow>
                                    <IconButton
                                    id='attachFileIconId'
                                      size='small'
                                      onClick={() => {
                                        setModuleId(module.id);
                                        setAddOpenReference(true);
                                        setSubModuleName(response.courseSubmoduleName)
                                        setModalBtnTxt('Update')
                                        setModelTitle('Edit Reference');
                                        console.log("onclick of edit ref", response)
                                        console.log("onclick of edit reference....>", module)
                                        // console.log("data",formValue)
                                        setReferenceValue({
                                          referencefile:response?.referencefile,
                                          referencetext:response?.referencetext,
                                          referenceFileView:response?.referencefile,
                                          referenceFileName:response?.referenceFileName,
                                          referenceid:response?.referenceid,
                                          subModuleId:response?.id
                                        })
                                        setFormType("editRef")
                                      }}
                                    >
                                      <AttachFileIcon />
                                    </IconButton>
                                    </Tooltip>
                                }

                              <Tooltip title="Edit submodule name" arrow>
                                <IconButton
                                  size="small"
                                  id='modeEditIconId'
                                  onClick={() => {
                                    setOpenSubModule(true);
                                    // getCourseDetailsById();
                                    setFormCategory('subModule')
                                    setFormType('EditSubmodule');
                                    setTextFieldName('Sub Module Name');
                                    setModelTitle('Edit Sub module');
                                    setModalBtnTxt('Update');
                                    setLabel('Include this sub module in trial course?');
                                    setisAuthor(// eslint-disable-next-line prefer-template     
                                    (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName)))
                                    setSubModuleId(response.id);
                                    setFormValue({
                                      courseTitle: response?.courseSubmoduleName,
                                      isTrial: response?.isTrial,
                                      videoFilePreview: response?.video,
                                    });
                                    setModuleId(module.id);
                                                                      }}
                                >
                                  <ModeEditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>

                              { // eslint-disable-next-line prefer-template     
                              (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName))&&<Tooltip title="Delete submodule" arrow>
                                <IconButton
                                  size="small"
                                  id='deleteIconForSubmoduleId'
                                  onClick={() => {
                                    setDeleteType('SUBMODULE');
                                    setDeleteAlert(true);
                                    setModuleId(module.id);
                                    setSubModuleId(response.id);
                                    setDeleteModalTitle('Are you sure you want delete this submodule?');
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>}
                            </div>
                          </Box>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </>
    );
  };

  // delete module videos
  const handleDeleteVideo = async () => {
    // let response = false;
    const alertRes = await swal({
      text: 'Are you sure you want to delete this video?',
      buttons: {
        confirm: {
          text: 'Okay',
          value: true,
          visible: true,
          className: '',
          closeModal: true,
        },
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: '',
          closeModal: true,
        },
      },
      closeOnClickOutside: false,
    });

    if (alertRes) {
      if (formType === 'EditModule') {
        const deleteResponse = await adminServices.handleDeleteModuleVideo(moduleId);
        if (deleteResponse.ok) {
          getCourseModules();
          setOpenSnackbar(true);
          setSnackbarTitle('Video deleted successfully');
          // return true;
        }
        if (!deleteResponse.ok) {
          setOpenSnackbar(true);
          setSnackbarTitle('Something went wrong please try again later');
        }
      } else {
        const deleteResponse = await adminServices.handleDeleteSubmoduleVideo(subModuleId);
        if (deleteResponse.ok) {
          getCourseModules();
          setOpenSnackbar(true);
          setSnackbarTitle('Video deleted successfully');
          // return true;
        }
        if (!deleteResponse.ok) {
          setOpenSnackbar(true);
          setSnackbarTitle('Something went wrong please try again later');
        }
      }
    }
    return true;
  };

  return (
    <Page title="Course-content">
      <div>
        <PageHeader
          pageTitle={courseDetails.title}
          goBack={isGoBack}
          buttonComponent={<ButtonGroup />}
          breadcrumbs={<Breadcrumb />}
        />

        {skeletonLoading ? (
          <CourseContentSkeleton />
        ) : (
          <>
            <Paper
              className={classes.paperContainer}
              sx={{ background: '#F9F9F9', border: '1px solid #BCBCBC', padding: '12px' }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sx={6} md={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Course Description
                  </Typography>
                  <Typography
                    className={isWantMoreText ? classes.showMoreText : null}
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: courseDetails.description }}
                  />
                  {courseDetails.description && courseDetails.description.length > 700 && (
                    <Typography
                      className={classes.readMore}
                      color="primary"
                      onClick={() => setIsWantMoreText(!isWantMoreText)}
                    >
                      {isWantMoreText ? 'Read more' : 'Read less'}
                    </Typography>
                  )}
                </Grid>
                <Grid item key="thumb-image" xs={12} sm={courseDetails.introVideo !== null ? 6 : false}
                 md={courseDetails.introVideo !== null ? 3 : 6} 
                 lg={courseDetails.introVideo !== null ? 3 : false} 
                 xl={courseDetails.introVideo !== null ? 3 : false}
                 >



                  <Typography variant="subtitle1" gutterBottom>
                    Thumb Image
                  </Typography>
                  <Box borderRadius={6} pb={1}>
                    <img
                      className={classes.thumbImg}
                      src={courseDetails.thumbImage}
                      width="350"
                      id='courseDetailsThumbImageId'
                      height="160"
                      alt="pic"
                    />
                  </Box>
                  <Typography variant="subtitle2" gutterBottom>
                    Course Category
                  </Typography>
                  <Box pt={1}>
                    <Typography variant="body2" className={classes.thumbBox}>
                      {courseDetails.category && courseDetails.category.description}
                    </Typography>
                  </Box>
                </Grid>

                {courseDetails.introVideo !== null && (
                  <Grid item xs={12} sm={false} md={3} xl={false}>
                    <Typography variant="subtitle1" gutterBottom>
                      Introduction Video
                    </Typography>
                   

                    <video width="95%" controls controlsList="nodownload">
                      <source src={courseDetails.introVideo} type="video/mp4" />
                      <track src={courseDetails.introVideo} kind="captions" label="english_captions" />
                    </video>

                   
                    <Typography variant="subtitle2" gutterBottom>
                      Keyword
                    </Typography>
                    <Box pt={1} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                      {courseDetails.hashTag &&
                        courseDetails.hashTag.map((res) => (
                          <Typography variant="body2" color="secondary" className={classes.hashTagBox}>
                            {res.description}
                          </Typography>
                        ))}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Paper>

            <Box
              display={'flex'}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
              pb={3}
              mb={2}
              sx={{ borderBottom: '1px solid #00B673 !important', paddingBottom: '12px !important' }}
            >
              <Typography variant="h4">Course Content</Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
              { // eslint-disable-next-line prefer-template     
              (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName))&&
                <Button
                variant="contained"
                id='addModuleId'
                startIcon={<AddIcon />}
                onClick={() => {
                  setOpenSubModule(true);
                  setTextFieldName('Module Name');
                  setModelTitle('Add Module');
                  setFormType('module');
                  setFormCategory('');
                  setModalBtnTxt('Create');
                  setisAuthor(// eslint-disable-next-line prefer-template     
                  (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName)))
                  setFormValue('');
                  setLabel('Include this module in trial course?');
                }}
                sx={{ marginRight: '10px' }}
              >
                Add Module
              </Button> }
              { // eslint-disable-next-line prefer-template     
              (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName))&&
              <ImportSubModuleButton courseId={courseId} userId={user.id} courses={courses}  onImportSuccess={handleImportSuccess} courseDetails={courseDetails} > Import Submodule</ImportSubModuleButton>}
              </div>
            </Box>

            <div>
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container" type="MODULE">
                  {(provided) => (
                    <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
                      {courseContent &&
                        courseContent.map((res, index) => (
                          <Draggable
                            key={res.positionIndex}
                            draggableId={res.positionIndex.toString()}
                            index={index}
                            type="TASK"
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                //  {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <Card className={classes.cardItem}>
                                  <CardActions disableSpacing>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: '16px',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <Box
                                        onClick={() => handleExpandClick(index)}
                                        sx={{ minWidth: '60%' }}
                                        {...provided.dragHandleProps}
                                      >
                                        <Typography sx={{ fontSize: '0.85rem' }}>
                                          Module
                                          {index + 1}
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ fontSize: '1.1rem' }}>
                                          {res.courseModuleName}
                                        </Typography>
                                      </Box>

                                      <Box style={{ display: 'flex', justifyContent: 'center',marginTop: '20px' }}>
                                        {res.video && (
                                          <Tooltip title="Watch video" arrow>
                                            <IconButton
                                              size="small"
                                              id='courseModuleIconId'
                                              onClick={() => {
                                                setOpenVideoModal(true);
                                                setVideoTitle({
                                                  title: res.courseModuleName,
                                                  video: res.video,
                                                });
                                              }}
                                            >
                                              <Iconify icon="pajamas:live-preview" width={20} height={20} />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      <Tooltip title="Edit">
                                        <IconButton
                                          size="small"
                                          id='modeEditIconcourseDetailsId'
                                          onClick={() => {
                                            setModuleId(res.id);
                                            setOpenSubModule(true);
                                            setFormType('EditModule');
                                            setTextFieldName('Module Name');
                                            setModelTitle('Edit Module');
                                            setisAuthor(// eslint-disable-next-line prefer-template     
                                            (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName)))
                                            setModalBtnTxt('Update');
                                            setFormCategory('');
                                            setLabel('Include this module in trial course?');
                                            setFormValue({
                                              courseTitle: res.courseModuleName,
                                              isTrial: res.isTrial,
                                              videoFilePreview: res.video,
                                            });
                                          }}
                                        >
                                          <ModeEditIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                        { // eslint-disable-next-line prefer-template     
                                        (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName))&&
                                        <Tooltip title="Delete">
                                          <IconButton
                                            size="small"
                                            id='deleteIconModuleId'
                                            sx={{ marginRight: '10px' }}
                                            onClick={() => {
                                              setDeleteType('MODULE');
                                              setDeleteAlert(true);
                                              setModuleId(res.id);
                                              setDeleteModalTitle('Are you sure you want delete this module?');
                                            }}
                                          >
                                            <DeleteIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>}
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                        { // eslint-disable-next-line prefer-template     
                                        (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName))&&
                                        <Tooltip title="Add Sub Module">
                                          <IconButton
                                            sx={{ fontSize: 'small' }}
                                            id='addIconModuleCourseId'
                                            onClick={() => {
                                              setModuleId(res.id);
                                              setOpenSubModule(true);                                             
                                              setFormType('subModule');
                                              setFormCategory('subModule');
                                              setTextFieldName('Sub Module Name');
                                              setModelTitle('Add Sub Module');
                                              setModalBtnTxt('Create');                   
                                              setisAuthor(// eslint-disable-next-line prefer-template     
                                              (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName)))
                                              setFormValue('');
                                              setLabel('Include this sub module in trial course?');
                                            }}
                                            
                                          >
                                            <AddIcon />
                                          </IconButton>
                                        </Tooltip>}
                                        <div>
                                        { // eslint-disable-next-line prefer-template    
                                        (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName))&&  courseDetails.type==='simulation' &&
                                        <ScreensValidation moduleId={res.id}  />
                                          }
                                        
                                        </div>
                                        </div>
                                          
                                            <ExpandMore
                                                expand={index}
                                                Tooltip="Show More"
                                                onClick={() => handleExpandClick(index)}
                                                aria-expanded={expanded}
                                                aria-label="show more"
                                                sx={{ marginLeft: '16px'}}
                                                id='showMoreIconId'
                                              >
                                              <ExpandMoreIcon />
                                            </ExpandMore>
                                          
                                      </Box>
                                    </div>
                                  </CardActions>

                                  <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                                    <CardContent>
                                      <SubmoduleComponent moduleNumber={index} module={res} subModule={res.subModule} />
                                    </CardContent>
                                  </Collapse>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            
            { // eslint-disable-next-line prefer-template     
              (user.role==='SUPER_ADMIN' || (courseDetails.authorList).includes(user.firstName + ' '+ user.lastName))&&
            <CaseStudies courseDetails={courseDetails} />}
          </>
        )}
      </div>
      <MoveSubModule
        open={openSubModuleMove}
        onClose={handleCloseMoveSubModule}
        onSubmit={handleSubmitMoveSubModule}
        from={moveFromModule}
        modules={courseContent}
        submoduleId= {moveSubmoduleId}
      />
      <AddRererenceModel
        open={openAddReference}
        onClose={handleCloseAddReference}
        modelWidth="xs"
        buttonText={modalBtnTxt}
        isAuthor={isAuthor}
        formCategory={formCategory}
        handleSubmit={submitReference}
        data={referenceValue}
        modelTitle={modelTitle}
        loading={btnLoading}
        formType={formType}
        setActionType={setActionType}
        deleteBtnLoading={deleteBtnLoading}
        updateBtnLoading={updateBtnLoading}
      />
      <AddSubModuleForm
        open={openSubModule}
        modelTitle={modelTitle}
        closeModel={() => {
          setOpenSubModule(false);
          setBtnLoading(false);
        }}
        textFieldName={textFieldName}
        handleSubmitForm={submitModuleForm}
        modelWidth="xs"
        buttonText={modalBtnTxt}
        isAuthor={isAuthor}
        loading={btnLoading}
        formCategory={formCategory}
        data={formValue}
        label={label}
        progress={progress}
        handleVideoDelete={() => handleDeleteVideo()}
        courseType={courseDetails.type}
      />

      <CreateCourse
        open={openModal}
        modelClose={() => setOpenModal(false)}
        submitForm={handleSubmitCourseForm}
        loading={btnLoading}
        data={courseData}
        title="Edit Course"
        formType="EDIT"
        deleteVideo={() => handleDeleteCourseVideo()}
        progress={progress}
      />

      <DeleteAlert
        open={deleteAlert}
        title={deleteModalTitle}
        confirm={handleDeleteSubModule}
        close={() => setDeleteAlert(false)}
      />

      <TrialModal
        open={openTrialModal}
        title="Set Trial Modules"
        close={handleCloseTrialModal}
        courseId={courseId}
        submit={handleSubmitTrialModal}
        loading={trialModalLoading}
      />

      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openVideoModal}
        // onClose={handleClose}
        closeAfterTransition
        sx={{
          backgroundColor: '#0a00008c !important',
          '.css-moysu4': {
            border: 'none !important',
          },
        }}
      >
        <Fade in={openVideoModal}>
          <Box className={classes.vediobox}>
            <Typography variant="h6" color="#fff" gutterBottom>
              {videoTitle.title}
            </Typography>
            <video width="520" controls style={{ width: '100%' }} autoPlay controlsList="nodownload">
              <source src={videoTitle.video} type="video/mp4" />
              <track src="captions_en.vtt" kind="captions" label="english_captions" />
            </video>
            <IconButton
              sx={{
                position: 'absolute',
                top: '24px',
                background: '#f6f6f6',
                // '.css-3u56ba-MuiButtonBase-root-MuiIconButton-root:hover': {
                //   backgroundColor: 'rgb(255 255 255 / 67%) !important',
                // },
              }}
              id='CloseIconForVideoId'
              className={classes.closeBtn}
              size="small"
              onClick={() => setOpenVideoModal(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Fade>
      </Modal>
    </Page>
  );
};

const useStyles = makeStyles((theme) => ({
  thumbImg: {
    width: '100%',
  },
  thumbBox: {
    width: 'max-content',
    background: '#fafafa',
    padding: '4px 8px',
    border: '1px solid #707070',
    borderRadius: '4px',
  },
  cardBox: {
    background: '#E6E6E6',
    padding: '12px',
    borderRadius: '4px',
    marginBottom: '8px',
  },
  editButton: {
    background: '#BCBCBC !important',
  },
  vediobox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '60%',
    height: 'auto',
    bgcolor: 'background.paper',
    padding: '20px',
    boxShadow: 24,
    p: 4,
  },
  closeBtn: {
    '&:hover': {
      backgroundColor: 'rgb(255 255 255 / 67%)',
    },
  },
  hashTagBox: {
    width: 'max-content',
    background: '#fafafa',
    padding: '4px 8px',
    marginRight: '8px',
    marginBottom: '8px',
    border: '1px solid #707070',
    borderRadius: '4px',
  },
  cardItem: {
    background: '#F9F9F9',
    marginBottom: '24px',
    border: '1px solid #e1e1e1ab',
  },
  showMoreText: {
    display: '-webkit-box',
    WebkitLineClamp: 11,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
  },
  readMore: {
    width: 'max-content',
    cursor: 'pointer',
  },
}));

export default CourseContent;
