import React from 'react';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const adminNavConfig = [
  {
    title: 'Dashboard',
    path: '/app/dashboard',
    icon: getIcon('akar-icons:dashboard'),
  },
  {
    title: 'Users',
    path: '/app/users',
    icon: getIcon('ph:users-three-bold'),
    children: [
      { title: 'Clients', path: '/app/client-users' },
      { title: 'Individual', path: '/app/individual-users' },
    ],
  },
  {
    title: 'Courses',
    path: '/app/course',
    icon: getIcon('bx:book-reader'),
  },
  {
    title: 'Products/Plans',
    path: '/app/subscription',
    icon: getIcon('ic:outline-sell'),
  },
  {
    title: 'Reports',
    path: '/app/reports',
    icon: getIcon('bi:bar-chart'),
    children: [
      { title: 'Trial Users', path: '/app/trial-users' },
      { title: 'Subscriptions', path: '/app/subscribed-users-new' },
      {
        title: 'User Course Progress',
        path: '/app/course-progress',
      },
    ],
  },
  {
    title: 'Tickets',
    path: '/app/feedback',
    icon: getIcon('fluent:person-feedback-24-regular'),
  },
];

const clientNavConfig = [
  {
    title: 'Dashboard',
    path: '/app/dashboard',
    icon: getIcon('akar-icons:dashboard'),
  },
  {
    title: 'Users',
    path: '/app/users',
    icon: getIcon('ph:users-three-bold'),
  },
  {
    title: 'Courses',
    path: '/app/course',
    icon: getIcon('bx:book-reader'),
  },
  {
    title: 'Analytics',
    path: '/app/analytics',
    icon: getIcon('bi:bar-chart'),
  },
];

export default { adminNavConfig, clientNavConfig };
