import React, { useEffect, useState, forwardRef } from 'react';
import { Breadcrumbs, Grid, Typography, Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import AssessmentIcon from '@mui/icons-material/Assessment';

// custom components
import Page from '../../../../components/Page';
import '../../clientDetails/Tabs.css';
import ActiveUserTrialTable from './ActiveTrialUsers';
import AllTrialUsers from './AllTrialUsers';
import ExpiredTrialUsers from './ExpiredTrialUsers';

import PageHeader from '../../../../components/PageHeader';
import Reports from '../../../../services/report/reports';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Index() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [activeUserTrialData, setActiveUserTrialData] = useState([]);
  const [activePageCount, setActivePageCount] = useState(0);

  const [expiredUserTrialData, setExpiredUserTrialData] = useState([]);
  const [expiredPageCount, setExpiredPageCount] = useState(0);

  const [allUserData, setAllUserData] = useState([]);
  const [allPageCount, setAllPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 86400000));
  const [endDate, setEndDate] = useState(new Date());
  const [filterColumn, setFilterColumn] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllTrialUsersData = async (
    page,
    rowsPerPage,
    sortOrder,
    sortKey,
    searchKey,
    filterValues = 'undefined,undefined,undefined,undefined'
  ) => {
    setAllUserData([]);
    setLoading(true);
    try {
      const responseBundle = await Reports.getTrialUserData(
        page,
        rowsPerPage,
        sortOrder,
        sortKey,
        searchKey,
        filterValues,
        `subscriptionPlanName,courseName,isPaid,status`
      );
      if (responseBundle.ok) {
        setAllUserData(responseBundle.data.records);
        setAllPageCount(responseBundle?.data?.metadata?.totalRecordCount);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActiveTrialUserData = async (
    page,
    rowsPerPage,
    sortOrder,
    sortKey,
    searchKey,
    filterValues = 'undefined,undefined,undefined,undefined'
  ) => {
    setActiveUserTrialData([]);
    setLoading(true);
    try {
      const responseIndividual = await Reports.getTrialUserData(
        page,
        rowsPerPage,
        sortOrder,
        sortKey,
        searchKey,
        filterValues,
        `subscriptionPlanName,courseName,isPaid,status`
      );
      if (responseIndividual.ok) {
        setActiveUserTrialData(responseIndividual.data.records);
        setActivePageCount(responseIndividual?.data?.metadata?.totalRecordCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getExpiredTrialUserData = async (
    page,
    rowsPerPage,
    sortOrder,
    sortKey,
    searchKey,
    filterValues = 'undefined,undefined,undefined,undefined'
  ) => {
    setExpiredUserTrialData([]);
    setLoading(true);
    try {
      const responseIndividual = await Reports.getTrialUserData(
        page,
        rowsPerPage,
        sortOrder,
        sortKey,
        searchKey,
        filterValues,
        `subscriptionPlanName,courseName,isPaid,status`
      );
      if (responseIndividual.ok) {
        setExpiredUserTrialData(responseIndividual.data.records);
        setExpiredPageCount(responseIndividual?.data?.metadata?.totalRecordCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllTrialUsersData('', 1, 25, 'desc', 'createDateTime', '', 'undefined,undefined,undefined,undefined');
    getActiveTrialUserData('ACTIVE', 1, 25, 'desc', 'createDateTime', '', 'undefined,undefined,undefined,undefined');
    getExpiredTrialUserData('EXPIRED', 1, 25, 'desc', 'createDateTime', '', 'undefined,undefined,undefined,undefined');
    setLoading(true);
  }, []);

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        Dashboard
      </Typography>
      <Typography variant="body2" color="textPrimary">
        Trial users
      </Typography>
    </Breadcrumbs>
  );

  return (
    <div>
      <Page title={'trialusers'}>
        <PageHeader pageTitle=" Trial users" breadcrumbs={<Breadcrumb />} />
        <Box style={{ padding: '0px' }}>
          {/* <Grid container>
            <Grid item xs="12" className={classes.gridAlign}>
              <Box className={classes.calenderStyle}>
                <Box sx={{ width: '100%', marginRight: '1rem' }}>
                  <Typography variant="h6" sx={{ width: '100%', fontWeight: ' 500', marginTop: '3px' }} gutterBottom>
                    Select Dates
                  </Typography>
                </Box>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  customInput={<ExampleCustomInput />}
                  todayButton="Today"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </Box>
            </Grid>
          </Grid> */}
          <Grid container spacing={2}>
            <Grid item xs="12">
              <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    sx={{
                      '.css-19l1g6u-MuiTabs-scroller': {
                        overflow: 'unset !important',
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab iconPosition="start" label="All" {...a11yProps(0)} />
                    <Tab iconPosition="start" label="Active" {...a11yProps(1)} />
                    <Tab iconPosition="start" label="Expired" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <AllTrialUsers
                    allUserData={allUserData}
                    pageCount={allPageCount}
                    loading={loading}
                    getAllTrialUsersData={getAllTrialUsersData}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ActiveUserTrialTable
                    activeUserTrialData={activeUserTrialData}
                    loading={loading}
                    pageCount={activePageCount}
                    getActiveTrialUserData={getActiveTrialUserData}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ExpiredTrialUsers
                    expiredUserTrialData={expiredUserTrialData}
                    pageCount={expiredPageCount}
                    loading={loading}
                    getExpiredTrialUserData={getExpiredTrialUserData}
                  />
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Page>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  calenderStyle: {
    position: 'absolute',
    zIndex: '111',
    display: 'flex',
    justifyContent: 'end',
  },
  gridAlign: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
      margin: '0rem 0rem 2rem 0rem',
    },
  },
}));
