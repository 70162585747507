import client from '../apiClient';

const getCourseList = () => client.get('/user/enroll/course');

const getSubscriptionList = () => client.get('/individual/user/subscription');

const getCourseModulesAndSubmodules = (courseId, planId) =>
  client.get(`/user/module/submodule/topics?courseId=${courseId}&&planId=${planId}`);

const getUserLastCompletedDetails = (courseId) => client.get(`/user/lastCompletedScreen?courseId=${courseId}`);

const getWebsiteIndividualCourses = (frequency) => client.get(`/website/course/individual?frequency=${frequency}`);

const getWebsiteBundleCourses = (frequency) => client.get(`/website/course/bundle?frequency=${frequency}`);

export default {
  getCourseList,
  getSubscriptionList,
  getCourseModulesAndSubmodules,
  getUserLastCompletedDetails,
  getWebsiteIndividualCourses,
  getWebsiteBundleCourses,
};
