// @mui
import { faker } from '@faker-js/faker';
import { Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
// components
import Page from '../components/Page';
import adminServices from '../services/adminServices';

// sections
import { AppWidgetSummary } from '../sections/@dashboard/app';
import BasicCard from '../components/cards/BasicCard';
import analytics from '../services/analytics/analytics';
import GreenClient from '../assets/logo/GreenClient.svg';
import Maskclient from '../assets/logo/Maskclient.svg';
import Subscription from '../assets/logo/Subscription.svg';
import UsersGreen from '../assets/logo/UsersGreen.svg';
import { DashboardLineChart } from './DahboardLineChart';
import PieChart from './PieChart';
import BarChart from './BarChart';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);

  const [activeUsers, setActiveUsers] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);
  const [courseProgress, setCourseProgress] = useState([]);
  const [dashBoardValue, setDashBoardValue] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({
    id: null,
    title: '',
  });

  const seriesusers = [
    {
      name: 'Active Users',
      data: activeUsers,
    },
  ];
  const options = {
    noData: {
      text: chartLoading ? 'Loading...' : 'No Data Found',
      align: 'center',
      verticalAlign: 'middle',
      style: {
        color: 'black',
        fontSize: '1.2rem',
        fontFamily: undefined,
        fontWeight: 600,
      },
    },

    grid: {
      show: true,
      borderColor: '#90A4AE',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        offsetX: 0,
        offsetY: 0,
        formatter(val) {
          return `${val}`;
        },
        style: {
          fontSize: '12px',
        },
      },
    },
    colors: ['#e96e28', '#FE8787'],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4,
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1,
          },
        },
      },
    },
    tooltip: {
      theme: false,
      fillSeriesColor: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        //  columnWidth: '40%',
        columnWidth: seriesusers.length * 20,
        height: 'auto',
        style: {
          overflow: 'auto',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      labels: {
        show: true,
        showAlways: false,
        showForNullSeries: false,
        rotate: -60,
        rotateAlways: true,
        minHeight: 100,
        maxHeight: 180,
        formatter(value, timestamp) {
          return value.length > 0 ? moment(value).format('DD-MM-YYYY') : '';
        },
      },
      crosshairs: {
        show: false,
        fill: {
          show: false,
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0,
            opacityTo: 0,
          },
        },
      },
    },

    fill: {
      opacity: 1,
    },
  };
  const seriesprogress = [
    {
      name: 'Course Progress',
      data: courseProgress,
    },
  ];
  const courseprogress = {
    options: {
      noData: {
        text: chartLoading ? 'Loading...' : 'No Data Found',
        align: 'center',
        verticalAlign: 'middle',
        style: {
          color: 'black',
          fontSize: '1.2rem',
          fontFamily: undefined,
          fontWeight: 600,
        },
      },
      tooltip: {
        theme: false,
        fillSeriesColor: false,
        onDatasetHover: {
          highlightDataSeries: false,
        },
        y: {
          formatter(value, timestamp) {
            return value;
          },
        },
      },
      legend: {
        show: false,
        position: 'top',
        offsetX: -25,
      },
      // colors: ['#e96e28', '#58b774', '#514DD1'],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: true,
          //  barHeight: '20px',
          barHeight: seriesprogress.length * 20,
          distributed: true,
        },
      },

      dataLabels: {
        enabled: false,
      },
      xaxis: {},
      yaxis: {
        show: true,

        min: (min) => {
          return 0;
        },
        max: (max) => {
          return 100;
        },

        labels: {
          show: true,
          offsetX: 0,
          offsetY: -4,
          formatter(value, timestamp) {
            return value.length > 0 ? value : '';
          },
        },
      },
    },
  };

  const getDashBoardValues = async () => {
    try {
      const response = await adminServices.getDashBoardValues();
      if (response.ok) {
        setDashBoardValue(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getactiveUsers = async (courseId) => {
    setActiveUsers([]);
    setChartLoading(true);

    try {
      const usersresponse = await analytics.activeUsers(courseId);
      if (usersresponse.ok) {
        if (
          (usersresponse?.data?.activeUsers.length > 0 && usersresponse?.data.activeUsers) ||
          (usersresponse?.data?.courseProgress.length > 0 && usersresponse?.data?.courseProgress)
        ) {
          const activeUsers = usersresponse?.data?.activeUsers.map((res) => {
            return {
              x: res.date,
              y: res.noOfUser,
            };
          });
          setActiveUsers(activeUsers);
          const courseProgressData = usersresponse?.data?.courseProgress.map((res) => {
            return {
              x: res.courseName,
              y: res.overallProgress,
            };
          });
          setCourseProgress(courseProgressData);
        } else {
          setChartLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCourseOptions = async () => {
    setChartLoading(true);
    try {
      const courseOption = await analytics.courseOptions();
      if (courseOption.ok) {
        if (courseOption.data.length > 0) {
          setCourseOptions(courseOption.data);
          setValue(courseOption.data[0]);
          getactiveUsers(courseOption.data[0].id);
        } else {
          setChartLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userRole === 'SUPER_ADMIN') {
      getDashBoardValues();
    }
    if (userRole !== 'SUPER_ADMIN') {
      getCourseOptions();
    }
  }, []);

  const cards = () => {
    return (
      <Box className={classes.customeSkeletonBox}>
        <Box>
          <Box className={classes.titleBox}>
            <Typography display="block" gutterBottom variant="caption" color="text.secondary" mt={0.5}>
              <Skeleton variant="rectangular" width={84} height={13} className={classes.skeletonTitle} />
            </Typography>
          </Box>
          <Box className={classes.containerBox} mt={0.5}>
            <Skeleton variant="rectangular" width={50} height={49} className={classes.rectangleSkeleton} />
            <Skeleton variant="rectangular" width={50} height={49} className={classes.circleSkeleton} />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Page title="Dashboard">
      <Typography variant="h4" className={classes.title}>
        Dashboard
      </Typography>

      {userRole === 'SUPER_ADMIN' && (
        <>
          {loading ? (
            <>
              <Grid container spacing={3}>
                  {[1, 2, 3, 4].map((card) => (
                      <Grid key={card} item xs={12} sm={6} md={3}>
                        {cards()}
                      </Grid>
                  ))}
              </Grid>
            </>
          ) : (
            <Grid container spacing={3}>
              {dashBoardValue &&
                dashBoardValue.map((data) => {
                  return (
                    <>
                      {data.table === 'CLIENT' && (
                        <Grid item xs={12} sm={6} md={3}>
                          <AppWidgetSummary
                            onClick={() => navigate('/app/client-users')}
                            sx={{
                              backgroundImage: `url(${Maskclient})`,
                              backgroundRepeat: 'no-repeat',
                              color: 'white',
                              backgroundSize: 'cover',
                              cursor: 'pointer',
                            }}
                            title="Clients"
                            total={data.count}
                            icon={'bxs:business'}
                          />
                        </Grid>
                      )}

                      {data.table === 'USER' && (
                        <Grid item xs={12} sm={6} md={3}>
                          <AppWidgetSummary
                            sx={{
                              backgroundImage: `url(${UsersGreen})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              color: 'white',
                            }}
                            title="Users"
                            total={data.count}
                            color="info"
                            icon={'fa-solid:users'}
                          />
                        </Grid>
                      )}



                      {data.table === 'COURSE' && (
                        <Grid item xs={12} sm={6} md={3}>
                          <AppWidgetSummary
                            sx={{
                              backgroundImage: `url(${GreenClient})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              color: 'white',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/app/course')}
                            title="Courses"
                            total={data.count}
                            color="warning"
                            icon={'bx:book-reader'}
                          />
                        </Grid>
                      )}
                      {data.table === 'SUBSCRIPTION' && (
                        <Grid item xs={12} sm={6} md={3}>
                          <AppWidgetSummary
                            sx={{
                              backgroundImage: `url(${Subscription})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              color: 'white',
                            }}
                            title="Subscription"
                            total={data.count}
                            color="warning"
                            icon={'ant-design:dollar-circle-filled'}
                          />
                        </Grid>
                      )}
                    </>
                  );
                })}
              <Grid item xs={12}>
                <div style={{ border: '1px solid #80808038', borderRadius: '4px' }}>
                  {' '}
                  <DashboardLineChart />
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={4}>
                <div style={{ border: '1px solid #80808038', borderRadius: '4px' }}>
                  {' '}
                  <PieChart />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <div style={{ border: '1px solid #80808038', borderRadius: '4px' }}>
                  {' '}
                  <BarChart />
                </div>
              </Grid> */}
            </Grid>
          )}
        </>
      )}

      {userRole !== 'SUPER_ADMIN' && (
        <Grid container spacing={2} mt={0}>
          <Grid item xs={12} md={12}>
            <Box className={classes.boxshodowone}>
              <div className={classes.flexdir}>
                <div>
                  <Typography variant="h5" gutterBottom>
                    Active Users
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Last 15 Days
                  </Typography>
                </div>
                <div>
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setValue(newValue);
                        getactiveUsers(newValue.id);
                      }
                    }}
                    clearIcon=""
                    id="controllable-Users-demo"
                    options={courseOptions}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.title}
                    size="small"
                    sx={{
                      width: 220,
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Course" />}
                  />
                </div>
              </div>
              <div>
                <ReactApexChart options={options} series={seriesusers} type="bar" height={395} />
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box className={classes.boxshodowone}>
              <div className={classes.paddingtitle}>
                <Typography variant="h5" gutterBottom>
                  Course Progress
                </Typography>
                <Typography variant="h5" gutterBottom />
              </div>
              <ReactApexChart options={courseprogress.options} series={seriesprogress} type="bar" height={420} />
            </Box>
          </Grid>
        </Grid>
      )}
    </Page>
  );
}

const useStyles = makeStyles((theme) => ({
  flexdir: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
  },
  paddingtitle: {
    padding: '1rem',
  },
  boxshodowone: {
    boxShadow: '0 2px 3px 0 rgb(32 40 45 / 8%)',
    borderRadius: '12px',
  },

  borderSkeleton: {
    borderRadius: '10px',
    width: '243px',
    height: '128px',
    [theme.breakpoints.down('md')]: {
      width: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'unset',
    },
  },

  borderSkeletonRelative: {
    borderRadius: '10px',
    position: 'relative',
    width: '50%',
    height: '128px',
    [theme.breakpoints.down('md')]: {
      width: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'unset',
    },
  },
  customeSkeletonBox: {
    height: '128px',
    borderRadius: '10px',
    animation: 'animation-c7515d 1.5s ease-in-out 0.5s infinite',
    backgroundColor: 'rgba(33, 43, 54, 0.11)',
    display: 'block',
    padding: '1rem',
  },

  skeletonTitle: {
    borderRadius: '10px',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  containerBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rectangleSkeleton: {
    borderRadius: '12px',
  },
  circleSkeleton: {
    borderRadius: '50px',
    marginRight: '0.5rem',
  },
  title: {
    marginBottom: '0.6rem',
    fontWeight: '500',
  },
}));
