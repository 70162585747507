import client from '../apiClient';

const getTopics = (subModuleId) => client.get(`/user/submodule/topics?courseSubModuleId=${subModuleId}`);

const getScreens = (topicId, actionType) =>
  client.get(`/user/topicScreen?submoduleTopicId=${topicId}
  &&actionType=${actionType}`);

const postResult = (payLoad) => client.post(`/user/result`, JSON.stringify(payLoad));

const pythonPostResult = (payLoad) => client.post(`/user/executePython`, JSON.stringify(payLoad));

export default {
  getTopics,
  getScreens,
  postResult,
  pythonPostResult
};
